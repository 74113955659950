import { IonButton, IonButtons, IonChip, IonContent, IonDatetime, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import ptBR from 'date-fns/locale/pt-BR';
import dayjs from 'dayjs';
import { arrowBack, calendar, options, storefront } from 'ionicons/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { CSVLink } from "react-csv";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { urlBase } from '../../../../../Utils/Services';
import AgendamentosTable from './AgendamentosTable';
import './RelatorioAgendamentos.css';
import { Buffer } from 'buffer';


const RelatorioAgendamentos: React.FC = () => {

  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [InstrucoesPages, setInstrucoesPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [stateFilterOpened, setStateFilterOpened] = useState(true);
  const [stateLoja, setStateLoja] = useState<any>();
  const [stateLojaNome, setStateLojaNome] = useState<any>();
  const [stateData, setStateData] = useState<any>(new Date().toISOString());
  const [stateLastEvaluatedKey, setStateLastEvaluatedKey] = useState<any>();
  const [stateAgendamentos, setStateAgendamentos] = useState<any>([]);
  const [stateCarregado, setStateCarregado] = useState<boolean>(false);
  const [stateStatus, setStateStatus] = useState<any>("ag");
  const [startDate, setStartDate] = useState<Date>();

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";
  });

  function tempoCorrido(horario: Date, dataFim?: Date) {
    var g = Math.ceil(((dataFim ? dataFim.getTime() : Date.now()) - horario.getTime()) / 1000 / 60);
    var tempoApurado = Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + 'min';
    return tempoApurado !== "0h00min" ? tempoApurado : "0h01min";
  }

  async function carregarAgendamentosSegmentada(filtro: any, lojasParam: any, data: Date) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let dataFormatada = dayjs(data).format('YYYY-MM-DD');

      let urlFinal;
      urlFinal = urlBase.url + "/agendamentos?filtro=" + filtro + "&lojas=" + Buffer.from(lojasParam).toString('base64') + "&valor=" + dataFormatada + "&relatorio=true";

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let res = await resp.json();

      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }

      if (res && res.LastEvaluatedKey) {
        setStateLastEvaluatedKey(res.LastEvaluatedKey);
      }
      else {
        setStateLastEvaluatedKey(null);
      }

      if (res && res.Items) {
        return res.Items;
      }
      else {
        return null;
      }
    };
    return await consultar();
  }

  function cnpj_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  async function carregarDados(situacao: any, cliente: any, data: any, exportFunction?: any) {
    if (situacao && cliente && data) {
      setStateFilterOpened(false);
      let loadResult = await carregarAgendamentosSegmentada(situacao, cliente, data);
      let arrayAgendamentos: any[] = [];

      arrayAgendamentos.push(['#', 'Hora', 'CNPJ', 'Agendamento', 'Pedido', 'Descrição', 'E-mail']);

      let contador = 0;

      loadResult.map((valor: any, index: any) => {
        console.log(valor);
        contador++;
        arrayAgendamentos.push(
          [
            contador,
            valor.horaAgendamento,
            cnpj_mask(valor.cnpj),
            valor.sequenciaAgendamento,
            valor.numeroPedido || ".",
            valor.descricaoAgendamento,
            valor.email || "."
          ]);

      });

      setStateAgendamentos(arrayAgendamentos);
      setStateCarregado(true);

      if (exportFunction) {
        exportFunction(arrayAgendamentos);
      }
    }
  }

  async function gerarRelatorioPDF(arrayAgendamentos: any) {

    pdfMake.vfs = pdfFonts.vfs;

    const reportTitle: any = [{
      text: 'Operações',
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45]  // left, top, riight, bottom
    }];

    const reportDetails: any = [
      { text: stateLoja, style: 'subheader' },
      { text: 'Data: ' + new Date(stateData || "").toLocaleString("pt-BR").substring(0, 10), style: 'subheader' },
      {
        table: {
          body: arrayAgendamentos

          /*[
            ['Loja', 'NF', 'Fornecedor', 'CNPJ', 'Data Entrega', 'Tipo Entrega', 'Chegada', 'Término', 'Duração', 'Status Entrega', 'Motorista', 'Placa Veículo'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
          ]*/
        },
        layout: {
          fillColor: function (rowIndex: any, node: any, columnIndex: any) {
            return (rowIndex == 0) ? '#FFA508' : (rowIndex % 2 === 0) ? '#CCCCCC' : null;
          }
        },
        style: 'padrao'
      }
    ];

    const reportFooter: any = [];

    function rodape(currentPage: any, pageCount: any) {
      return [
        {
          text: currentPage + ' / ' + pageCount,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0]
        }
      ]
    }

    const docDefinitions: any = {
      pageSize: 'A4',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      pageMargin: [15, 50, 15, 40],

      //header: [reportTitle],
      content: [reportDetails],
      footer: rodape,
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        padrao: {
          fontSize: 8
        }
      }
    }

    pdfMake.createPdf(docDefinitions).download();
    //pdfMake.createPdf(docDefinitions).open();
    //pdfMake.createPdf(docDefinitions).print();
  }

  return (
    <IonPage id='paginaRelatorioAgendamentos' className='paginaRelatorioAgendamentos' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton onClick={() => { setStateAgendamentos([]); setStateCarregado(false); history.goBack(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonIcon slot="icon-only" icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></IonButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Relatório Agendamentos</IonTitle>
          <IonButtons slot="end" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonButton onClick={() => setStateFilterOpened(!stateFilterOpened)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonIcon color={stateFilterOpened ? 'danger' : 'dark'} icon={options} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon></IonButton></IonButtons>
        </IonToolbar>
        <IonToolbar color='primary' hidden={!stateFilterOpened} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div style={{ position: 'absolute', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0, width: 'max-content' }}>
            <IonChip outline style={{ background: 'white', paddingLeft: 0, paddingRight: 0, height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <div hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true}>
                <IonChip outline style={{ background: 'white', border: 'none', height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonIcon icon={storefront} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
                  <IonSelect name='Lojas' placeholder='Loja' title='Lojas' multiple={false} cancelText="Cancel" okText="Ok" onIonChange={(e) => { setStateLoja(e.detail.value.split('|')[1]); setStateLojaNome(e.detail.value.split('|')[0]); carregarDados(stateStatus, e.detail.value.split('|')[0], startDate); } } onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.clienteLojaId + '|' + valor.clienteLojaNome} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonChip>
                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>|</IonLabel>
              </div>
              <IonChip outline style={{ background: 'white', border: 'none', height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div>
                  <ReactDatePicker
                    onFocus={e => e.target.blur()}
                    placeholderText='Data'
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    portalId='paginaRelatorioAgendamentos'
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date || new Date());
                      carregarDados(stateStatus, stateLojaNome, (date || new Date()));
                    }}
                  />
                </div>
                <IonIcon icon={calendar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
              </IonChip>
            </IonChip>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div style={{ textAlign: "center" }} hidden={true/*stateCarregado*/}>
          <IonItem placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Data</IonLabel>
            <IonDatetime value={stateData} onIonChange={(e) => { setStateData(e.detail.value); } } min='2022-03-01' max={dayjs(new Date()).toISOString()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonDatetime>
            <IonIcon slot='end' icon={calendar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonItem>
          <IonItem hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Loja</IonLabel>
            <IonSelect placeholder='Selecione a loja' onIonChange={(e) => { setStateLoja(e.detail.value.split('|')[1]); setStateLojaNome(e.detail.value.split('|')[0]); } } onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                <IonSelectOption value={valor.clienteLojaId + '|' + valor.clienteLojaNome} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {/* <IonItem>
            <IonLabel>Status</IonLabel>
            <IonSelect value={stateStatus} onIonChange={(e) => { setStateStatus(e.detail.value); }}>
              <IonSelectOption value={'ag'}>Agendados</IonSelectOption>
              <IonSelectOption value={'fi'}>Finalizados</IonSelectOption>
            </IonSelect>
          </IonItem> */}

          <p></p>
          <IonButton onClick={async () => { if (stateLoja == undefined) return; setStateCarregado(true); await carregarDados(stateStatus, stateLojaNome, stateData); } } expand="block" type="submit" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Buscar</IonButton>
        </div>
        <div style={{ textAlign: "center" }} hidden={!stateCarregado}>
          <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{stateLoja}</IonLabel>
          </IonItem>
          <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Data: {dayjs(startDate).format("DD/MM/YYYY")}</IonLabel>
          </IonItem>
          <p></p>
          <IonButton onClick={() => gerarRelatorioPDF(stateAgendamentos)} type="button" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Gerar PDF</IonButton>
          <CSVLink data={stateAgendamentos} separator={";"}><IonButton type="button" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Gerar CSV</IonButton></CSVLink>
          <IonButton hidden={true} onClick={() => { setStateAgendamentos([]); setStateCarregado(false); } } type="button" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Limpar</IonButton>
          <p></p>
          <AgendamentosTable model={stateAgendamentos}></AgendamentosTable>
        </div>
        <p></p>
      </IonContent>
    </IonPage>
  );
};

export default RelatorioAgendamentos;