import { IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonLoading, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, thumbsUpSharp, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clienteCarregar, instrucoesExcluir, requestService } from '../../../../../Utils/Services';
import InstrucoesCad from '../InstrucoesCad/InstrucoesCad';
import './Instrucoes.css';
import { Buffer } from 'buffer';

const Instrucoes: React.FC = () => {
  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [InstrucoesPages, setInstrucoesPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayInstrucoes, setarrayInstrucoes] = useState<any>({});
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [instrucoes, setInstrucoes] = useState("");
  const [showExcluirInstrucoes, setShowExcluirInstrucoes] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [chamarServico, setChamarServico] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showExcluding, setShowExcluding] = useState(false);
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";
    {carregarInstrucoes()}
  });

  const loadItens2 = () => {

    if (arrayInstrucoes === undefined || arrayInstrucoes.length === 0) {
      return (
        <IonItem lines="none" color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Não há benefícios cadastrados</IonLabel>
        </IonItem>
      );
    }
    
    if (Array.isArray(arrayInstrucoes) && arrayInstrucoes.length > 0) {

      return arrayInstrucoes?.map((value: any, index: any, arr: any) =>
        <IonItem color="light" lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonIcon color='medium' slot="start" icon={thumbsUpSharp} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          <IonLabel className="ion-text-wrap" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{value}</IonLabel>
          <IonIcon color='medium' slot="end" icon={trash} onClick={() => { setInstrucoes(value); setMensagem("Excluir benefício <strong>" + value + "</strong>?"); setShowExcluirInstrucoes(true); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </IonItem>
      );
    }

  }

  function carregarInstrucoes(loja?: any, autorizado?: any) {
    const consultar = async () => {

      let id = window.localStorage.getItem("cliente") || '';

      requestService(clienteCarregar.url.replace("{id}", id).concat("?filtro=", Buffer.from(loja || lojas).toString("base64")), {
        method: clienteCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          // if (response.Item.instrucoes) {
          //   window.localStorage.setItem("instrucoes", JSON.stringify(response.Item.instrucoes));
          // }
          // else {
          //   window.localStorage.removeItem("instrucoes");
          // }
          setarrayInstrucoes(response?.Item?.instrucoes || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    }

    if (chamarServico || autorizado) {
      setChamarServico(false);
      consultar();
    }
  }

  async function closeModal() {
    setShowDetalhes(false);
    setChamarServico(true);
    carregarInstrucoes();
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Benefícios ao Motorista</IonTitle>
          <IonSelect name='Lojas' placeholder='Selecione a loja' title='Lojas' value={lojas} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); carregarInstrucoes(e.detail.value, true); } } slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
              <IonSelectOption value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
            ))}
          </IonSelect>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        
        <IonModal isOpen={showDetalhes} onDidDismiss={() => { setShowDetalhes(false); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <InstrucoesCad closeAction={closeModal} clienteId={lojas || ""}></InstrucoesCad>
        </IonModal>

        <IonItemDivider color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonLabel className='instrucoes' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            Benefícios disponíveis
          </IonLabel>
        </IonItemDivider>
        {loadItens2()}




        <IonAlert
          isOpen={showExcluirInstrucoes}
          onDidDismiss={() => setShowExcluirInstrucoes(false)}
          header={'Exclusão de benefício'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',

              handler: () => {
                setShowExcluding(true);

                const objectRequest = {
                  instrucao: instrucoes
                }

                console.log(instrucoes);

                let id = window.localStorage.getItem("cliente") || '';

                if (window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) {
                  id = lojas;
                }

                requestService(instrucoesExcluir.url.replace("{id}", id.replaceAll("#", "%23")), {
                  method: instrucoesExcluir.method,
                  body: JSON.stringify(objectRequest),
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    setChamarServico(true);
                    carregarInstrucoes();
                    setTextToast(response.message);
                    setShowSucesso(true);
                    setShowExcluding(false);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                    setShowExcluding(false);
                  });
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showExcluding}
          onDidDismiss={() => { setShowExcluding(false); }}
          message={'Excluindo...'}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); }}
          message={'Excluído!'}
          duration={1200}
          animated={false}
        />

        <IonAlert
          isOpen={showSucesso}
          onDidDismiss={() => setShowSucesso(false)}
          header={'Sucesso'}
          message={textToast}
          buttons={['OK']}
        />

        <IonAlert
          isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
      <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          <IonButton onClick={() => setShowDetalhes(true)} expand="block" type="submit" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Incluir benefício</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Instrucoes;
