import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { IonAvatar, IonButton, IonButtons, IonChip, IonContent, IonFab, IonFabButton, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { add, alertCircle, arrowBack, arrowDown, arrowUp, checkmarkCircle, checkmarkCircleSharp, closeCircle, ellipseOutline, flag, options, refresh, search, storefront, swapVertical } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { incluirOperacao, requestService, urlBase } from '../../../../../Utils/Services';
import './Operacoes.css';
import OperacoesList from './OperacoesList';
//import logo from '../../../../../theme/logos/logo_violeta.png'
import { Buffer } from 'buffer';

import validator from 'validator';
import OperacoesFiltroLojas from './OperacoesFiltroLojas';
import OperacoesPesquisar from './OperacoesPesquisar';
import { LocalNotifications } from '@capacitor/local-notifications';
import logo from '../../../../../theme/icons/LogoCor.png'

type Props = {
  recarregar: boolean;
};

const OperacoesNovo: React.FC<Props> = (props: Props) => {

  const inputRef = useRef<any>(null);
  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [showModalFiltroLojas, setShowModalFiltroLojas] = useState(false);

  const [showModalPesquisar, setShowModalPesquisar] = useState(false);
  const [showModalFiltroOpcoes, setShowModalFiltroOpcoes] = useState(false);
  const [ordenar, setOrdenar] = useState(true);
  const [pesquisa, setPesquisa] = useState<string>();
  const [stateStatusSituacaoLiberacao, setStateSituacaoLiberacao] = useState<boolean>();
  const [stateChipLiberadoSelected, setStateChipLiberadoSelected] = useState<boolean>(true);
  const [stateChipEmLiberacaoSelected, setStateChipEmLiberacaoSelected] = useState<boolean>(true);
  const [stateChipStatusOperacaoFilter, setStateChipStatusOperacaoFilter] = useState<any>({ iniciado: true, emLiberacao: true, tratativa: true, liberado: true })
  const [showLoading, setShowLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [statusOperacao, setStatusOperacao] = useState<string>('Em');

  const [showModalTipoOperacao, setShowModalTipoOperacao] = useState(false);
  const [codigoBarras, setCodigoBarras] = React.useState('0');
  const [lojaCadastro, setLojaCadastro] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");
  const [tipoOperacao, setTipoOperacao] = useState("Entrega");
  const [tipoCarga, setTipoCarga] = useState(window.localStorage.getItem("tipoCarga") || "Seca");
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [placa, setPlaca] = useState("");
  const [stateNotificacoes, setStateNotificacoes] = useState(false);

  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);

  const [lojas, setLojas] = useState<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const openedWindowRef = useRef<Window | null>(null);

  useIonViewWillEnter(() => { setPesquisa(""); setShowModalPesquisar(false); })

  // Use effect utilizado para cadastrar um timer somente quando entra na pagina a primeira vez, 
  // nas demais ele não roda devido não ter nenhum state atrelado
  // O timer é para verificar se há novas operacoes e assim enviar windows notification para o usuario que
  // marcou para receber os avisos
  useEffect(() => {
    //console.log("Entrou no use effect");
    // Configurar a notificação para ocorrer a cada 5 minutos (300.000ms)
    const interval = setInterval(async () => {
      if (JSON.parse(window.localStorage.getItem("notificacoes") || "false")) {
        checkNewOperations();
        console.log(openedWindowRef);
      }
    }, 300000);

    // Limpar o intervalo ao desmontar o componente
    return () => { 
      //console.log("cancelado interval"); 
      clearInterval(interval);
    };
  }, []);

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
    if (window.localStorage.getItem("reloadOperacoes") == "sim") {
      window.localStorage.setItem("reloadOperacoes", "não");
      setShowLoading(!showLoading);
    }
  });

  const showNotificationWeb2 = async (novasOperacoes: any) => {

    const permission = await LocalNotifications.requestPermissions();
    if (permission.display === "granted") {
      // Criar e exibir a notificação
      await LocalNotifications.schedule({
        notifications: [
          {
            id: 1,
            title: "Mensagem de Notificação",
            body: "Você clicou no botão!",
            schedule: { at: new Date(new Date().getTime() + 1000) }, // Exibe após 1 segundo
            sound: undefined,
            attachments: undefined,
            actionTypeId: "",
            extra: {route:'/cliente/operacoesNovo'},
          },
        ],
      });

      LocalNotifications.addListener('localNotificationActionPerformed', (payload) => {
        //const route = payload.notification.extra.route;
        // use route to redirect
        openedWindowRef.current?.open();
        history.replace({pathname: payload.notification.extra.route});
      });

    } else {
      alert("Permissão para notificações não concedida.");
    }



  }

  const showNotificationWeb = async (novasOperacoes: any) => {

    // Solicitar permissão para notificações
    if (Notification.permission === "granted") {
      // Exibir a notificação
      let not = new Notification("Novas operações registradas", {
        body: "Novas operações registradas: " + novasOperacoes,
        icon: logo,
        // "https://example.com/icon.png", // Substitua pelo URL de um ícone, se necessário
      });
      

      not.addEventListener("click",(ev:any)=>{window.self.focus();console.log("entrou"); setShowLoading(!showLoading)});

      /*
      not.onclick = () => {
        const targetUrl = "https://localhost:8100/cliente/operacoesNovo";

        // Verifica se a janela já foi aberta
        if (openedWindowRef.current) {
          // Caso já tenha sido aberta, traz para frente
          openedWindowRef.current?.focus();
        } else {
          // Usar `window.open` para abrir nova janela e armazenar a referência
          const newWindow = window.open(targetUrl, "_blank");

          if (newWindow) {
            // Armazena a janela aberta para monitorar
            openedWindowRef.current = newWindow;
          } else {
            console.log("Não foi possível abrir uma nova janela.");
          }
        }
      }
      */
    } else if (Notification.permission !== "denied") {
      // Solicitar permissão ao usuário
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        new Notification("Novas operações registradas", {
          body: "Novas operações registradas: " + novasOperacoes,
          //icon: "https://example.com/icon.png", // Substitua pelo URL de um ícone, se necessário
        });
      } else {
        alert("Permissão para notificações foi negada.");
      }
    } else {
      alert("Permissão para notificações já foi negada anteriormente.");
    }
  };

  // função que verifica e manda uma notificação de browser caso haja alguma operacao nova com data mais recente do que a anotada na ultima consulta ao backend
  async function checkNewOperations() {
    let filtro = "Em";
    let lojasFiltro = Buffer.from(JSON.stringify(lojas)).toString('base64');
    let ordenar = false;
    let datahoraMaiorOperacao = new Date(window.localStorage.getItem("maiorDataOperacao") || "");
    let datahoraUltimaConsulta = new Date(new Date(window.localStorage.getItem("maiorDataOperacao") || "").getTime() + 1);

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    fetch(urlBase.url + "/operacoes?filtro=" + filtro + "&lojas=" + lojasFiltro + "&ordenar=" + ordenar + "&datahora=" + datahoraUltimaConsulta.toISOString(),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      }).then((resp: Response) => {
        if (resp.status == 401) {
          console.log("erro de serviço");
        }
        else {
          resp.json().then((res: any) => {
            //console.log("res", res);

            if (res.Count > 0) {
              let varDataInicio = datahoraMaiorOperacao.toISOString();

              res.Items.map((item: any) => {
                varDataInicio = item.dataInicio > varDataInicio ? item.dataInicio : varDataInicio;
              })

              window.localStorage.setItem("maiorDataOperacao", varDataInicio);
              //showNotificationWeb(res.Count);
              showNotificationWeb(res.Count);
            }

          }).catch((e: any) => console.log(e));
        }
      }).catch((e: any) => { console.log(e); });
  }

  const showNotification = async () => {
    // Solicitar permissão para notificações
    const permission = await LocalNotifications.requestPermissions();
    if (permission.display === "granted") {
      console.log("mensagem");
      // Criar e exibir a notificação
      await LocalNotifications.schedule({
        notifications: [
          {
            id: 1,
            title: "Mensagem de Notificação",
            body: "Você clicou no botão!",
            schedule: { at: new Date(new Date().getTime() + 1000) }, // Exibe após 1 segundo
            sound: undefined,
            attachments: undefined,
            actionTypeId: "",
            extra: null,
          },
        ],
      });
    } else {
      alert("Permissão para notificações não concedida.");
    }
  };

  function onChangeCPF(e: any) {
    e.persist();
    e.preventDefault();

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    // console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }

  const openScanner = async () => {

    const opt: BarcodeScannerOptions = {
      formats: 'CODE_128',
      prompt: 'Escaneie o codigo de barras'
    }

    const data: any = await BarcodeScanner.scan(opt);

    if (data !== null) {
      setCodigoBarras(data.text.match(/(.{1,4})/g).join(" "));
      setShowModalCodigoBarrasDigitar(true);
    }

  };

  function validarCampos() {
    if (nome.length < 3 || cpf.length != 14 || telefone.length != 15 || placa.length === 0) {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }
    else {
      if (!validarCpfNovo(cpf)) {
        setTextToast("CPF Inválido.");
        setShowToast(true);
      }
      else if (!validator.isMobilePhone(telefone, 'pt-BR')) {
        setTextToast("Celular Inválido.");
        setShowToast(true);
      }
      else if (placa.length != 7) {
        setTextToast("Placa Inválida.");
        setShowToast(true);
      }
      else {
        return true;
      }
    }
  }

  function validarCpfNovo(val: any): boolean {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else { return false; }
  }

  function iniciarOperacao() {
    if (!validarCampos())
      return;

    if (codigoBarras === undefined || codigoBarras.replace(/\ /, '') === '') {
      setAlertaPreenchimento(true);
    }
    else {
      if (botaoAtivo) {
        setBotaoAtivo(false);
        setShowModalCodigoBarrasDigitar(false);

        let objRequest = {
          "motoristaNome": nome,
          "motoristaDocumento": cpf || "",
          "motoristaTelefone": telefone || "",
          "motoristaPlaca": placa || "",
          "tipoOperacao": tipoOperacao,
          "tipoCarga": tipoCarga,
          "chaveAcesso": codigoBarras.replace(/ /g, ""),
          "loja": (lojaCadastro && lojaCadastro != "") ? lojaCadastro : ""
        };

        requestService(incluirOperacao.url, {
          method: incluirOperacao.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          body: JSON.stringify(objRequest),
          credentials: 'include'
        },
          history,
          (response: any) => {
            if (response.message) {
              setBotaoAtivo(true);
              setShowLoading(!showLoading);
              setShowModalCodigoBarrasDigitar(false);
              setShowModalTipoOperacao(false);
              //carregarOperacoesSegmentada(statusOperacao);
              setTextToast("Operação criada com sucesso!");
              setShowToast(true);
            }
          },
          (error: any) => {
            setTextToast("Erro ao tentar inserir! Tente novamente!");
            setShowToast(true);
            setBotaoAtivo(true);
          })

      }
    }
  }

  function cancelarScan() {
    setShowModalCodigoBarrasDigitar(false);
    //setShowModalCodigoBarras(false);
  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };

  function hideModalPesquisar() {
    setPesquisa("");
    setShowModalPesquisar(false);
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons hidden={true} slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon slot="icon-only" icon={flag} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
          </IonButtons>
          <IonAvatar slot='start' style={{ marginLeft: '10px', width: '48px', height: '48px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <img src={window.localStorage.getItem("logo") ? "/assets/logos/" + window.localStorage.getItem("logo") + ".png" : "/assets/logos/saidamax.png"} />
          </IonAvatar>
          <IonTitle style={{ paddingLeft: '12px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações</IonTitle>
          <IonButtons slot="end" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton onClick={() => setOrdenar(!ordenar)} hidden={true || statusOperacao != 'Em'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon slot="icon-only" icon={swapVertical} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            </IonButton>
            <IonButton onClick={() => { setPesquisa(""); setShowModalPesquisar(!showModalPesquisar); }} color={showModalPesquisar ? 'danger' : 'dark'} hidden={statusOperacao != 'Em'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon slot="icon-only" icon={search} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
            <IonButton onClick={() => { setPesquisa(""); setShowModalFiltroOpcoes(!showModalFiltroOpcoes); }} color={showModalFiltroOpcoes ? 'danger' : 'danger'} hidden={statusOperacao != 'Em'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon slot="icon-only" icon={options} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
            <IonButton hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} onClick={() => setShowModalFiltroLojas(true)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonIcon slot="icon-only" icon={storefront} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color="primary" hidden={!showModalPesquisar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonSearchbar debounce={700} value={pesquisa} onIonClear={(e) => setPesquisa("")} onIonChange={(e) => { setPesquisa(e.detail.value); }} placeholder='Pesquise pelas notas que estão aparecendo nesta tela' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonSearchbar>
        </IonToolbar>
        <IonToolbar hidden={!showModalFiltroOpcoes} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonItem lines='full' color='primary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel style={{ background: '#fff', padding: '10px', borderRadius: '2.5px', boxShadow: '0px 1px 3px gray' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <p style={{ marginBottom: '5px', color: '#000' }}><b>Filtros</b></p>
              <p style={{ width: 'auto', marginTop: '5px', color: '#333', borderBottom: '0px solid rgba(0,0,0,0.5)' }}>Status da Operação</p>
              <IonChip onClick={() => { setStateChipStatusOperacaoFilter({ ...stateChipStatusOperacaoFilter, iniciado: !stateChipStatusOperacaoFilter.iniciado }); }} outline={true} style={{ marginLeft: '0px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonIcon icon={stateChipStatusOperacaoFilter.iniciado ? checkmarkCircleSharp : ellipseOutline} style={{ color: 'black' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon><IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Iniciado</IonLabel></IonChip>
              <IonChip onClick={() => { setStateChipStatusOperacaoFilter({ ...stateChipStatusOperacaoFilter, emLiberacao: !stateChipStatusOperacaoFilter.emLiberacao }); }} outline={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonIcon icon={stateChipStatusOperacaoFilter.emLiberacao ? checkmarkCircleSharp : ellipseOutline} style={{ color: 'black' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon><IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Em liberação</IonLabel></IonChip>
              <IonChip onClick={() => { setStateChipStatusOperacaoFilter({ ...stateChipStatusOperacaoFilter, tratativa: !stateChipStatusOperacaoFilter.tratativa }); }} outline={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonIcon icon={stateChipStatusOperacaoFilter.tratativa ? checkmarkCircleSharp : ellipseOutline} style={{ color: 'black' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon><IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Tratativa</IonLabel></IonChip>
              <IonChip onClick={() => { setStateChipStatusOperacaoFilter({ ...stateChipStatusOperacaoFilter, liberado: !stateChipStatusOperacaoFilter.liberado }); }} outline={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonIcon icon={stateChipStatusOperacaoFilter.liberado ? checkmarkCircleSharp : ellipseOutline} style={{ color: 'black' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon><IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Liberado</IonLabel></IonChip>
            </IonLabel>
          </IonItem>
        </IonToolbar>
        <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonSegment value={statusOperacao} onIonChange={(e) => { }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonSegmentButton value="Em" layout="icon-end" onClick={(e) => { if (statusOperacao == 'Em') { hideModalPesquisar(); setOrdenar(!ordenar); } else { setStatusOperacao('Em'); } }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Andamento</IonLabel>
              <IonIcon color='medium' icon={ordenar ? arrowDown : arrowUp} hidden={statusOperacao != 'Em'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
            </IonSegmentButton>
            <IonSegmentButton value="Concluido" onClick={(e) => { setShowModalPesquisar(false); if (statusOperacao != 'Concluido') { setStatusOperacao('Concluido'); } hideModalPesquisar(); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Concluídos</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollY={false} scrollEvents={true} ref={contentRef} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <IonFab vertical="top" horizontal="end" slot="fixed" edge placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          <IonFabButton color="tertiary" onClick={() => {
            setCPF("");
            setNome("");
            setTelefone("");
            setPlaca("");
            setShowModalTipoOperacao(true);
          }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon color="light" icon={add} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonFab vertical="top" horizontal="start" slot="fixed" edge placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          <IonFabButton color="tertiary" onClick={() => { setShowLoading(!showLoading); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon color="light" icon={refresh} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonFabButton>
        </IonFab>

        <OperacoesList filtro={statusOperacao} lojas={Buffer.from(JSON.stringify(lojas)).toString('base64')} reload={showLoading} router={history} usaLiberacao={true} ordenar={ordenar} pesquisa={pesquisa} chipStatusOperacaoFilter={stateChipStatusOperacaoFilter} />

        <IonModal isOpen={showModalFiltroLojas} onDidDismiss={() => setShowModalFiltroLojas(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <OperacoesFiltroLojas lojas={lojas} setLojas={setLojas} setShowModalFiltroLojas={setShowModalFiltroLojas} />
        </IonModal>

        <IonModal isOpen={false} onDidDismiss={() => setShowModalPesquisar(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <OperacoesPesquisar setShowModalPesquisar={setShowModalPesquisar} router={history} />
        </IonModal>

        <div style={{ height: '200px' }}></div>

        <div className="content">

          <IonModal isOpen={showModalCodigoBarrasDigitar} onDidPresent={() => { inputRef.current.setFocus(); }} onDidDismiss={() => { setCodigoBarras('0'); setShowModalCodigoBarrasDigitar(false); }} onWillPresent={() => { setCodigoBarras(''); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Informe a Nota Fiscal</IonTitle>
                <IonButtons slot="start" onClick={() => { cancelarScan(); setShowModalCodigoBarrasDigitar(false); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonIcon slot="icon-only" icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "fontSize": "14px" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonLabel className="ion-text-wrap" position="stacked" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Informe a <b>chave de acesso</b> (código de barras) na <b>nota</b>:</IonLabel>
                <IonTextarea ref={(ref) => inputRef.current = ref} rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="clique aqui para digitar ou utilize o leitor de código de barras" onIonChange={e => space(e.detail.value!, 4)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonTextarea>
              </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonButton expand="block" size='large' disabled={!botaoAtivo} onClick={() => { iniciarOperacao(); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Iniciar Operação!</IonButton>
                <IonButton expand="block" fill="clear" color="tertiary" onClick={() => { openScanner(); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Utilizar câmera do celular</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>

          <IonModal isOpen={showModalTipoOperacao} onDidDismiss={() => setShowModalTipoOperacao(false)} onWillPresent={() => { setCodigoBarras('0'); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cadastro de Operação</IonTitle>
                <IonButtons slot="start" onClick={() => setShowModalTipoOperacao(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonIcon slot="icon-only" icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonList lines="full" class="ion-no-margin" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Dados da Operação
                  </IonLabel>
                </IonListHeader>
                <IonItem lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Loja</IonLabel>
                  <IonSelect name='lojaCadastro' placeholder='Selecione a loja' title='Lojas' value={lojaCadastro} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojaCadastro(e.detail.value); }} slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any, index: any) => (
                      <IonSelectOption key={"operacoesNovoIonSelectOption" + index} value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Tipo de operação</IonLabel>
                  <IonSelect value={tipoOperacao} onIonChange={(e) => { setTipoOperacao(e.detail.value || ""); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonSelectOption value="Entrega" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Entrega</IonSelectOption>
                    <IonSelectOption value="Coleta" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Coleta</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Carga</IonLabel>
                  <IonSelect value={tipoCarga} onIonChange={(e) => { window.localStorage.setItem("tipoCarga", e.detail.value || ""); setTipoCarga(e.detail.value || ""); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonSelectOption value="Seca" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Seca</IonSelectOption>
                    <IonSelectOption value="Perecível" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Perecível</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem hidden={true} lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Carga Paletizada</IonLabel>
                  <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
              </IonList>

              <IonList lines="full" class="ion-no-margin" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Informações do Motorista
                  </IonLabel>
                </IonListHeader>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Nome*</IonLabel>
                  <IonInput placeholder="Nome do motorista" value={nome} required onIonInput={(e: any) => setNome(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >CPF*</IonLabel>
                  <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Celular*</IonLabel>
                  <IonInput type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Veículo*</IonLabel>
                  <IonInput placeholder="Digite a placa do veículo" value={placa} required maxlength={7} onIonInput={(e: any) => setPlaca(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
              </IonList>
            </IonContent>
            <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonButton expand="block" size='large' color="primary" onClick={() => { if (!validarCampos()) { return; }; if (tipoOperacao === "Entrega") { setShowModalCodigoBarrasDigitar(true); } else { iniciarOperacao(); } }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Registrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>


        </div>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={1000}
        />

        {/*
        <IonRefresher slot="fixed" onIonRefresh={(e: any) => { setShowLoading(!showLoading); setTimeout(() => { e.detail.complete(); }, 1000); }}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
         */}

      </IonContent>
    </IonPage >
  );
};

export default OperacoesNovo;


