import { IonContent, IonPage, IonButton, IonToast, IonHeader, IonToolbar, IonTitle, IonFooter, IonListHeader, IonAvatar, IonList, IonItem, IonLabel, IonText, IonIcon, IonButtons, IonModal, useIonViewWillEnter, useIonViewDidEnter, IonNote, IonAlert } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ContaParceiro.css';
import '@capacitor-community/http';
import { deslogar, requestService } from '../../../../../../Utils/Services';
import avatar from './imgs/avatar.svg'
import { albums, alertCircle, arrowBackCircleOutline, car, card, cube, exit, grid, heart, help, home, information, informationCircle, informationCircleOutline, list, lockClosed, person, personCircle, personCircleOutline, qrCode, search, settings, statsChart, statsChartOutline, thumbsUp, volumeMediumOutline, wallet } from 'ionicons/icons';

const ContaParceiro: React.FC = () => {

    const history = useHistory();
    const [showToast2, setShowToast2] = useState(false);

    useIonViewDidEnter(() => {
        document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
    });

    return (
        <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonIcon slot="icon-only" icon={person} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{localStorage.getItem("nome") || "Conta"}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className="conta" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {/* <IonListHeader lines="full">Benefícios</IonListHeader>
                <IonItem lines="none" button routerLink="/cliente/instrucoes" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={thumbsUp} />
                    <IonLabel>Benefícios ao motorista</IonLabel>
                </IonItem> */}
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Informações</IonListHeader>
                <IonItem lines="none" button onClick={() => { } } detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={help} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Ajuda</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/sobre" routerDirection="forward" onClick={() => { } } detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={information} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Sobre</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/termospolitica" routerDirection="forward" onClick={() => { } } detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={lockClosed} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Privacidade e termos de uso</IonLabel>
                </IonItem>
                <IonItem lines="none" button onClick={() => { setShowToast2(true); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                    <IonIcon color='medium' slot="start" icon={exit} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Sair</IonLabel>
                    <IonAlert
                        isOpen={showToast2}
                        onDidDismiss={() => setShowToast2(false)}
                        cssClass='my-custom-class'
                        header={'Sair do Saidamax'}
                        message={'Deseja realmente <strong>desconectar</strong> de sua conta?'}
                        buttons={[
                            {
                                text: 'Cancelar',
                                role: 'cancel',
                            },
                            {
                                text: 'Sair',
                                cssClass: 'primary',
                                handler: () => {
                                    requestService(deslogar.url, {
                                        method: deslogar.method,
                                        headers: [["token", localStorage.getItem("token") || ""]],
                                        credentials: 'include'
                                    },
                                        history,
                                        (response: any) => {
                                            localStorage.clear();
                                            history.replace("/login/");
                                        },
                                        (error: any) => {
                                            localStorage.clear();
                                            history.replace("/login/");
                                        });
                                }
                            }
                        ]}
                    />
                </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={informationCircleOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Versão</IonLabel>
                    <IonNote slot="end" className="footeritemnote" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>1.6.3</IonNote>
                </IonItem>
            </IonFooter>
        </IonPage>
    );
};

export default ContaParceiro;
