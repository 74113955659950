import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonRefresher, IonRefresherContent, IonSelect, IonSelectOption, /*IonSlide, IonSlides,*/ IonText, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import Rating from '@material-ui/lab/Rating';
import { calendar, podium } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Relatorios.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import { Buffer } from 'buffer';

//import { carregaOperacao, listaOperacaoCompartilhada, requestService } from '../../../../../Utils/Services';

import { Bar, Pie } from 'react-chartjs-2';
import { reportsBuscar } from '../../../../../Utils/Services';

const Relatorios: React.FC = () => {

  const options: any = {
    default: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      }
    },
    operacoes: {
      scales: {
        y: {
          ticks: {
            beginAtZero: true,
          },
          grid: {
            display: false,
            drawBorder: true,
          }
        },
        x: {
          ticks: {
            beginAtZero: true,
          },
          grid: {
            display: false,
            drawBorder: true,
          }
        },
      },
      options: {
        plugins: {
          legend: { display: false }
        },
        legend: { display: false }
      },
      plugins: {
        legend: { display: false }
      },
      legend: { display: false }
    },
    HorizontalBar: {
      indexAxis: 'y',
      // Elements options apply to all of the options unless overridden in a dataset
      // In this case, we are setting the border of each horizontal bar to be 2px wide
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
      responsive: true,
      //aspectRatio: 2, 
      //maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
        title: {
          display: true,
          text: 'Resumo',
        },
      },
      scales: {
        x: {
          gridLines: {
            drawBorder: false,

          },
          ticks: {
            display: false
          },
          grid: {
            display: false,
            drawBorder: false,
          }
        },
        y:
        {
          gridLines: {
            drawBorder: false,

          },
          ticks: {
            display: true,
          },
          grid: {
            display: false,
            drawBorder: false,
          }
        }
      }
    }
  };


  const chartUsuariosBase = {
    labels: [' administradores', ' usuários'],
    datasets: [
      {
        //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
        data: [0, 0],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1,
      },
    ],
  }

  const chartOperacoeBase = {
    labels: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
    options: {
      plugins: {
        legend: { display: false }
      }
    },
    datasets: [
      {
        //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
        type: 'bar',
        label: 'Operações',
        data: [],
        backgroundColor: '#1D3460',//'rgb(255, 99, 132)',
        borderColor: 'white',
        borderWidth: 1,
      },
    ],
  }

  const chartOperacoesAvaliacoesBase = {
    labels: ['5', '4', '3', '2', '1'],
    options: {
      indexAxis: 'y',
      plugins: {
        legend: { position: 'right' }
      }
    },
    datasets: [
      {
        //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
        type: 'bar',
        label: 'Operações',
        data: [],
        backgroundColor: '#ff6a14',//'#1D3460',//'rgb(255, 99, 132)',
        borderColor: 'white',
        borderWidth: 1,
        //barThickness: 20,
        maxBarThickness: 15,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y:
          {
            grid: {
              display: false
            }
          }
        }
      },
    ],
  }

  //  console.log("Loadging: Relatorios")
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [aba, setAba] = useState("proximos");
  const [RelatoriosPages, setRelatoriosPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [carregado, setCarregado] = useState(false);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [textButton, setTextButton] = useState("Próximo");
  const [classIrLogin, setClassIrLogin] = useState("");
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  const [operacaoId, setOperacaoId] = useState("");
  const [chartUsuarios, setChartUsuarios] = useState<any>(chartUsuariosBase);
  const [chartDiaria, setChartDiaria] = useState<any>({});
  const [chartDiariaEncerrados, setChartDiariaEncerrados] = useState<any>({});
  const [chartOperacoesIniciadas, setChartOperacoesIniciadas] = useState<any>(chartOperacoeBase);
  const [chartOperacoesEncerradas, setChartOperacoesEncerradas] = useState<any>(chartOperacoeBase);
  const [chartOperacoesAvaliacoes, setChartOperacoesAvaliacoes] = useState<any>(chartOperacoesAvaliacoesBase);
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");
  const [startDate, setStartDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState<any>('');

  useIonViewWillEnter(() => {
    //setChamadaRealizada(false);
    //carregarOperacoes();
  });

  const loadOperacoes = () => {
    return arrayOperacoes?.map((value: any, index: any, arr: any) =>
      (value.relatorio === "operacoesAtivas") ?
        (
          <IonCard key={value.tipo} onClick={() => { history.replace("/cliente/operacoesNovo"); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardHeader id={value.tipo} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações em andamento</IonCardTitle>
              <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonCardSubtitle>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
            </IonCardHeader>
            <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <div className="chartOperacoesAtivas">{value.total}</div>
              <div className="container">
                <div className="box">
                  <div className="box-row">
                    <div className="box-cell">{value.totalEntrega || 0}<div>Entrega</div></div>
                    <div className="box-cell">{value.totalColeta || 0}<div>Coleta</div></div>
                    <div className="box-cell">{value.totalCarregamento || 0}<div>Carregamento</div></div>
                  </div>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        ) :
        (null)
    );
  };

  const loadItens = () => {

    if (!chamadaRealizada) {
      setChamadaRealizada(true);
      carregarOperacoes();
    }

    //Coloca card informando que não gráficos a serem exibidos
    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="nodata" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonCardHeader id="nodata" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Sem gráficos a serem exibidos</IonCardTitle>
            <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonCardSubtitle>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
          </IonCardHeader>
          <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          </IonCardContent>
        </IonCard>
      );
    }

    return arrayOperacoes?.map((value: any, index: any, arr: any) =>
      (value.tipo === "charts#usuarios") ?
        (
          <IonCard key={value.tipo} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonCardHeader id={value.tipo} onClick={() => { history.replace("/cliente/equipe"); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Equipe cadastrada</IonCardTitle>
              <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Contas com acesso ao Saidamax</IonCardSubtitle>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
            </IonCardHeader>
            <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <Pie data={chartUsuarios} options={options.default} />
            </IonCardContent>
          </IonCard>
        ) :
        (value.relatorio === "operacoesIniciadas") ?
          (
            <><IonCard key={value.tipo} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardHeader id={value.tipo} onClick={() => { history.replace("/cliente/operacoesNovo"); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações iniciadas</IonCardTitle>
                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
              </IonCardHeader>
              <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <Bar data={chartOperacoesIniciadas} options={options.operacoes} />
              </IonCardContent>
            </IonCard></>
          ) :
          (value.relatorio === "operacoesEncerradas") ?
            (
              <><IonCard placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCardHeader id={value.tipo + "2"} onClick={() => { history.replace("/cliente/operacoesNovo"); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações encerradas</IonCardTitle>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
                </IonCardHeader>
                <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <Bar data={chartOperacoesEncerradas} options={options.operacoes} />
                </IonCardContent>
              </IonCard></>
            ) :
            (value.relatorio === "operacoesAtivas") ?
              (null) :
              (value.relatorio === "operacoesTotais") ?
                (
                  <IonCard key={value.tipo} onClick={() => { history.replace("/cliente/operacoesNovo"); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonCardHeader id={value.tipo} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações Hoje</IonCardTitle>
                      <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonCardSubtitle>
                      <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
                    </IonCardHeader>
                    <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <div className="chartOperacoesAtivas">{value.totais.total}</div>
                      <div className="container">
                        <div className="box">
                          <div className="box-row">
                            <div className="box-cell">{value.totais.totalEntrega || 0}<div>Entrega</div></div>
                            <div className="box-cell">{value.totais.totalColeta || 0}<div>Coleta</div></div>
                            <div className="box-cell">{value.totais.totalCarregamento || 0}<div>Carregamento</div></div>
                          </div>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                ) :
                (value.relatorio === "operacoesAvaliacoes") ?
                  (
                    <><IonCard key={value.tipo} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <IonCardHeader id={value.tipo} onClick={() => { history.replace("/cliente/operacoesNovo"); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Avaliação</IonCardTitle>
                        <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
                      </IonCardHeader>
                      <IonCardContent style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <div className="chartOperacoesAtivas">{(value.avaliacoes.media).toFixed(1)}</div>
                        <Rating name="half-rating" value={value.avaliacoes.media} defaultValue={value.avaliacoes.media} precision={0.5} />
                        <div style={{ "marginBottom": "20px" }}><IonText placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{value.avaliacoes.avaliacoes || 0} avaliações</IonText></div>
                        <div style={{ background: "#f2f2f2", display: "block", borderRadius: 10, "marginTop": "30px", "paddingLeft": "10px", "paddingRight": "10px", maxWidth: "500px", margin: "auto" }}>
                          <Bar data={chartOperacoesAvaliacoes} options={options.HorizontalBar} />
                        </div>
                      </IonCardContent>
                    </IonCard></>
                  ) :
                  (null)
    );

    /*
        return (<IonCard>
          <IonCardHeader key="usuarios" >
            <IonCardTitle>Usuários Cadastrados</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
            <Pie data={chartUsuarios} options={options} />
          </IonCardContent>
        </IonCard>);
    */
  }

  function carregarGraficos(element: any, index: any, array: any) {

    if (element.tipo === "charts#usuarios") {
      setChartUsuarios({
        labels: [element.chartUsuariosAdm.toString() + ' administradores', element.chartUsuariosUsuarios.toString() + ' usuários'],
        datasets: [
          {
            //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
            data: [element.chartUsuariosAdm, element.chartUsuariosUsuarios],
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1,
          },
        ],
      });
    }
    else if (element.relatorio === "operacoesIniciadas") {
      setChartOperacoesIniciadas({
        labels: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
        options: {
          plugins: {
            legend: { display: false }
          }
        },
        datasets: [
          {
            //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
            type: 'bar',
            label: 'Operações',
            data: [
              element.operacoesIniciadas?.h0 || 0,
              element.operacoesIniciadas?.h1 || 0,
              element.operacoesIniciadas?.h2 || 0,
              element.operacoesIniciadas?.h3 || 0,
              element.operacoesIniciadas?.h4 || 0,
              element.operacoesIniciadas?.h5 || 0,
              element.operacoesIniciadas?.h6 || 0,
              element.operacoesIniciadas?.h7 || 0,
              element.operacoesIniciadas?.h8 || 0,
              element.operacoesIniciadas?.h9 || 0,
              element.operacoesIniciadas?.h10 || 0,
              element.operacoesIniciadas?.h11 || 0,
              element.operacoesIniciadas?.h12 || 0,
              element.operacoesIniciadas?.h13 || 0,
              element.operacoesIniciadas?.h14 || 0,
              element.operacoesIniciadas?.h15 || 0,
              element.operacoesIniciadas?.h16 || 0,
              element.operacoesIniciadas?.h17 || 0,
              element.operacoesIniciadas?.h18 || 0,
              element.operacoesIniciadas?.h19 || 0,
              element.operacoesIniciadas?.h20 || 0,
              element.operacoesIniciadas?.h21 || 0,
              element.operacoesIniciadas?.h22 || 0,
              element.operacoesIniciadas?.h23 || 0
            ],
            backgroundColor: '#1D3460',//'rgb(255, 99, 132)',
            borderColor: 'white',
            borderWidth: 1,
          },
        ],
      });
    }
    else if (element.relatorio === "operacoesEncerradas") {
      setChartOperacoesEncerradas({
        labels: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
        options: {
          plugins: {
            legend: { display: false }
          }
        },
        datasets: [
          {
            //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
            type: 'bar',
            label: 'Operações',
            data: [
              element.operacoesEncerradas?.h0 || 0,
              element.operacoesEncerradas?.h1 || 0,
              element.operacoesEncerradas?.h2 || 0,
              element.operacoesEncerradas?.h3 || 0,
              element.operacoesEncerradas?.h4 || 0,
              element.operacoesEncerradas?.h5 || 0,
              element.operacoesEncerradas?.h6 || 0,
              element.operacoesEncerradas?.h7 || 0,
              element.operacoesEncerradas?.h8 || 0,
              element.operacoesEncerradas?.h9 || 0,
              element.operacoesEncerradas?.h10 || 0,
              element.operacoesEncerradas?.h11 || 0,
              element.operacoesEncerradas?.h12 || 0,
              element.operacoesEncerradas?.h13 || 0,
              element.operacoesEncerradas?.h14 || 0,
              element.operacoesEncerradas?.h15 || 0,
              element.operacoesEncerradas?.h16 || 0,
              element.operacoesEncerradas?.h17 || 0,
              element.operacoesEncerradas?.h18 || 0,
              element.operacoesEncerradas?.h19 || 0,
              element.operacoesEncerradas?.h20 || 0,
              element.operacoesEncerradas?.h21 || 0,
              element.operacoesEncerradas?.h22 || 0,
              element.operacoesEncerradas?.h23 || 0
            ],
            backgroundColor: '#ff6a14',//'#1D3460',//'rgb(255, 99, 132)',
            borderColor: 'white',
            borderWidth: 1,
          },
        ],
      });
    }
    else if (element.relatorio === "operacoesAvaliacoes") {
      setChartOperacoesAvaliacoes({
        labels: ['5', '4', '3', '2', '1'],
        options: {
          indexAxis: 'y',
          plugins: {
            legend: { position: 'right' }
          }
        },
        datasets: [
          {
            //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
            type: 'bar',
            label: 'Operações',
            data: [
              element.avaliacoes?.[5] || 0,
              element.avaliacoes?.[4] || 0,
              element.avaliacoes?.[3] || 0,
              element.avaliacoes?.[2] || 0,
              element.avaliacoes?.[1] || 0,
            ],
            backgroundColor: '#ff6a14',//'#1D3460',//'rgb(255, 99, 132)',
            borderColor: 'white',
            borderWidth: 1,
            //barThickness: 20,
            maxBarThickness: 15,
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y:
              {
                grid: {
                  display: false
                }
              }
            }
          },
        ],
      });
    }
    else {

    }

  }

  function carregarOperacoes(loja?: any, data?: any) {
    const consultar = async () => {

      let filtroLoja = Buffer.from(loja || lojas).toString("base64");
      let filtroData = "";

      if (filterDate && filterDate != '') {
        filtroData = "&data=" + filterDate;
      }

      if (data) {
        filtroData = "&data=" + data;
      }

      const resp = await fetch(reportsBuscar.url.concat("?filtro=", filtroLoja, filtroData),
        {
          method: reportsBuscar.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });
      const operacoes = await resp.json();

      if (operacoes && operacoes.Items) {
        setArrayOperacoes(operacoes.Items);

        operacoes.Items.forEach(carregarGraficos);
      }
    };

    consultar();
  }

  function tempoCorrido(horario: Date, horarioFim: Date) {
    var g = Math.ceil((horarioFim.getTime() - horario.getTime()) / 1000 / 60);
    return Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + 'min';
  }

  function doRefresh(event: any) {
    // console.log('Begin async operation');
    //carregarOperacoes();
    // console.log("refresh" + statusOperacao);
    carregarOperacoes();
    setTimeout(() => {
      // console.log('Async operation has ended');
      event.detail.complete();
    }, 1200);
  }

  return (
    <IonPage class="relatorios" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon slot="icon-only" icon={podium} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Relatórios</IonTitle>
          <IonSelect name='Lojas' placeholder='Selecione as lojas' title='Lojas' value={lojas} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); carregarOperacoes(e.detail.value); } } slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
              <IonSelectOption value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
            ))}
          </IonSelect>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"Relatorios"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRefresherContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonRefresherContent>
        </IonRefresher>
        <div style={{ background: '#F2F2F2' }}>
          <div style={{ position: 'relative', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0, width: 'max-content' }}>
            <IonChip outline style={{ background: 'white', paddingLeft: 0, paddingRight: 0, height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonChip outline style={{ background: 'white', border: 'none', height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div>
                  <DatePicker
                    onFocus={e => e.target.blur()}
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR} portalId='paginaAgendamentos'
                    selected={startDate}
                    onChange={(date) => {
                      let dataSelecionada = (date || new Date());
                      setStartDate(dataSelecionada);
                      setFilterDate(dataSelecionada.getFullYear().toString().concat((dataSelecionada.getMonth() + 1).toString().padStart(2, '0')).concat(dataSelecionada.getDate().toString().padStart(2, '0')));
                      carregarOperacoes(lojas, dataSelecionada.getFullYear().toString().concat((dataSelecionada.getMonth() + 1).toString().padStart(2, '0')).concat(dataSelecionada.getDate().toString().padStart(2, '0')));
                    }}
                  />
                </div>
                <IonIcon icon={calendar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
              </IonChip>
            </IonChip>
          </div>
{/* 
          <IonSlides className={"RelatoriosSlides"} >
            <IonSlide>
              {loadOperacoes()}
              {loadItens()}
            </IonSlide>
          </IonSlides>
*/}
          <div className={"RelatoriosSlides"} >
            <div>
              {loadOperacoes()}
              {loadItens()}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default Relatorios;
