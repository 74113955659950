import { IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonLoading, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, bookmarkOutline, thumbsUpSharp, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clienteCarregar, instrucoesExcluir, operacaoSalvaBuscar, requestService } from '../../../../../Utils/Services';
import InstrucoesCad from '../InstrucoesCad/InstrucoesCad';
import './OperacoesSalvas.css';
import { Buffer } from 'buffer';

const OperacoesSalvas: React.FC = () => {
  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [InstrucoesPages, setInstrucoesPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoesSalvas, setarrayOperacoesSalvas] = useState<any>({});
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [instrucoes, setInstrucoes] = useState("");
  const [showExcluirInstrucoes, setShowExcluirInstrucoes] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [chamarServico, setChamarServico] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showExcluding, setShowExcluding] = useState(false);
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";
    { carregarOperacoesSalvas() }
  });

  const loadItens2 = () => {

    if (arrayOperacoesSalvas === undefined || arrayOperacoesSalvas.length === 0) {
      return (
        <IonItem lines="none" color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Não há operações salvas para acompanhamento</IonLabel>
        </IonItem>
      );
    }

    if (Array.isArray(arrayOperacoesSalvas) && arrayOperacoesSalvas.length > 0) {

      return arrayOperacoesSalvas?.map((value: any, index: any, arr: any) => (
        <IonItem detail button color="light" lines="inset" onClick={() => { history.push("operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: value.id.replace(/#/g, '%23') }); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonIcon color='medium' slot="start" icon={bookmarkOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          <IonLabel className="ion-text-wrap" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <h3><b>{value.operacaoTipoOperacao + " " + new Date(value.dataInicio).toLocaleString()}</b></h3>
            <h4>{(value.notasAdicionais && value.notasAdicionais.length > 0) ? ((value.notasAdicionais[0].razaoSocial || "") + " (" + (value.notasAdicionais[0].cnpj || "") + ")") : ""}</h4>
            <h4>{(value.notasAdicionais && value.notasAdicionais.length > 0) ? "notas: " + (value.notasAdicionais.map((value: any) => value.numeroNotaFiscal).join(", ")) : ""}</h4>
          </IonLabel>
          <IonIcon hidden={true} color='medium' slot="end" icon={trash} onClick={() => { setInstrucoes(value); setMensagem("Excluir benefício <strong>" + value + "</strong>?"); setShowExcluirInstrucoes(true); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </IonItem>
      )
      );
    }

  }

  function carregarOperacoesSalvas(loja?: any, autorizado?: any) {
    const consultar = async () => {

      let id = window.localStorage.getItem("cliente") || '';

      // caso tenha passado a loja, prepara o objeto para enviar na url
      if (loja) {
        loja = loja.replace(/#/g, "%23")
      }

      console.log("loja", loja);

      requestService(operacaoSalvaBuscar.url.replace("{pk}", loja || id), {
        method: operacaoSalvaBuscar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          // if (response.Item.instrucoes) {
          //   window.localStorage.setItem("instrucoes", JSON.stringify(response.Item.instrucoes));
          // }
          // else {
          //   window.localStorage.removeItem("instrucoes");
          // }
          setarrayOperacoesSalvas(response?.Items || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    }

    if (chamarServico || autorizado) {
      setChamarServico(false);
      consultar();
    }
  }

  async function closeModal() {
    setShowDetalhes(false);
    setChamarServico(true);
    carregarOperacoesSalvas();
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações Salvas</IonTitle>
          <IonSelect name='Lojas' placeholder='Selecione a loja' title='Lojas' value={lojas} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); carregarOperacoesSalvas(e.detail.value, true); }} slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
              <IonSelectOption value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
            ))}
          </IonSelect>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>

        <IonModal isOpen={showDetalhes} onDidDismiss={() => { setShowDetalhes(false); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <InstrucoesCad closeAction={closeModal} clienteId={lojas || ""}></InstrucoesCad>
        </IonModal>

        {loadItens2()}

        <IonAlert
          isOpen={showExcluirInstrucoes}
          onDidDismiss={() => setShowExcluirInstrucoes(false)}
          header={'Exclusão de benefício'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',

              handler: () => {
                setShowExcluding(true);

                const objectRequest = {
                  instrucao: instrucoes
                }

                console.log(instrucoes);

                let id = window.localStorage.getItem("cliente") || '';

                if (window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) {
                  id = lojas;
                }

                requestService(instrucoesExcluir.url.replace("{id}", id.replaceAll("#", "%23")), {
                  method: instrucoesExcluir.method,
                  body: JSON.stringify(objectRequest),
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    setChamarServico(true);
                    carregarOperacoesSalvas();
                    setTextToast(response.message);
                    setShowSucesso(true);
                    setShowExcluding(false);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                    setShowExcluding(false);
                  });
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showExcluding}
          onDidDismiss={() => { setShowExcluding(false); }}
          message={'Excluindo...'}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); }}
          message={'Excluído!'}
          duration={1200}
          animated={false}
        />

        <IonAlert
          isOpen={showSucesso}
          onDidDismiss={() => setShowSucesso(false)}
          header={'Sucesso'}
          message={textToast}
          buttons={['OK']}
        />

        <IonAlert
          isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
    </IonPage>
  );
};

export default OperacoesSalvas;
