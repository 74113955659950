import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonToast, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, useIonViewDidEnter, isPlatform, useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './ValidarOtp.css';
import imgTopo from './imgs/topo.png';
import { useHistory } from 'react-router-dom';
import { confirmarLogin, enviarEmail, requestService } from '../../../Utils/Services';
import { arrowBack } from 'ionicons/icons';
// import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
// import { authentication } from '../../../firebase-config';


const ValidarOtp: React.FC = () => {

  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Validar Número");
  const [codigo, setCodigo] = useState("");
  const [confirmation, setConfirmation] = useState<any>();
  const [desabilitarEnviarEmail, setDesabilitarEnviarEmail] = useState(false);


  const inputRef = useRef<any>(null);

  useEffect(() => {
    inputRef.current.setFocus();
  });

  useIonViewDidEnter(() => {
    setDesabilitarEnviarEmail(false);
    //setBotaoAtivo(false);

    // var nome = 'divRecaptcha' + new Date().toLocaleTimeString();
    // let elementToAdd = document.createElement("div");
    // elementToAdd.setAttribute('id', nome);

    // let div = document.getElementById('recaptcha-container');
    // if (div) div.append(elementToAdd);

    // if (!isPlatform('ios')) {
    //   let recpVerify = new RecaptchaVerifier(nome, {
    //     'size': 'invisible',
    //     'callback': (response: any) => {
    //     }
    //   }, authentication);

    //   console.log("telefone", window.localStorage.getItem("telefone"));
    //   signInWithPhoneNumber(authentication, '+' + window.localStorage.getItem("telefone"), recpVerify)
    //     .then((confirmationResult) => {
    //       setConfirmation(confirmationResult);
    //       setBotaoAtivo(true);
    //       console.log(confirmationResult);
    //     }).catch((error) => {
    //       console.log(error);
    //       setTextToast("Não foi possível enviar o SMS.");
    //       setShowToast(true);
    //       setBotaoAtivo(true);
    //       history.goBack();
    //     });
    // }
    // else {
    setBotaoAtivo(true);
    //}

    setCodigo("");
    setTextBotao("Validar Número");



    inputRef.current.setFocus();
  });

  useIonViewWillLeave(() => {
    setShowToast(false);
  });

  const validar = () => {
    setBotaoAtivo(false);
    setTextBotao("Aguarde...");

    // if (!isPlatform('ios')) {
    //   confirmation.confirm(codigo).then((result: any) => {
    //     console.log(result);
    //     const user = result?.user;

    //     login(user?.accessToken);


    //   }).catch((error: any) => {
    //     console.log(error);
    //     login();
    //     // setTextToast("Token inválido");
    //     // setShowToast(true);
    //     // setCodigo("");
    //     // setBotaoAtivo(true);
    //     // setTextBotao("Validar Número");
    //   });
    // }
    // else {
    login();
    //}

  }


  function login(accessToken?: any) {

    const objectRequest = {
      otp: codigo.toString(),
      accessToken: accessToken,
      telefone: window.localStorage.getItem("telefone")
    }

    requestService(
      confirmarLogin.url.replace("{cpf}", window.localStorage.getItem("cpf") || ""),
      {
        method: confirmarLogin.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      history,
      (response: any) => {
        //window.localStorage.clear();
        window.localStorage.setItem("cliente", response.gsi2pk.replace(/#/g, '%23'));
        window.localStorage.setItem("logado", "true");
        window.localStorage.setItem("categoria", response.categoria);
        if (response.lojas) window.localStorage.setItem("lojas", JSON.stringify(response.lojas));
        window.localStorage.setItem("acessos", JSON.stringify(response.acessos));

        // pega qual logo deve ser carregado na barra da tela de operacoes
        if (response.logo) {
          window.localStorage.setItem("logo", response.logo)
        }

        setBotaoAtivo(true);
        setCodigo("");
        setTextBotao("Validar Número");
        if (response.categoria == "cliente") {
          history.push("/cliente");
        }
        else {
          history.push("/cliente/validartokenmotorista");
        }
      },
      (error: any) => {
        setTextToast("Token inválido");
        setShowToast(true);
        setCodigo("");
        setBotaoAtivo(true);
        setTextBotao("Validar Número");
      }
    );
  }

  const email = () => {

    requestService(
      enviarEmail.url.replace("{cpf}", window.localStorage.getItem("cpf") || ""),
      {
        method: enviarEmail.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        //body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      history,
      (response: any) => {
        console.log("response", response.message);
        setTextToast(response.message);
        setShowToast(true);
        setDesabilitarEnviarEmail(true);
        // window.localStorage.setItem("logado", "true")
        // window.localStorage.setItem("token", response.token);
        //history.replace("/cliente/operacoes");
      },
      (error: any) => {
        setTextToast("Ocorreu um erro ao enviar o e-mail");
        setShowToast(true);
      }
    );
  }


  function onChangeCodigo(e: any) {
    e.persist();
    e.preventDefault();
    setCodigo(e.target.value);

    if (e.keyCode === 13) {
      if (botaoAtivo) validar();
    }
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>VALIDE SEU CELULAR</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"ValidarLogin"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonLabel className="titulo" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Insira o <strong>código</strong> que você irá receber por <strong>SMS</strong> no celular informado:</IonLabel>
        <div className="input">
          <span>Token</span>
          <IonInput ref={(ref) => inputRef.current = ref} type="number" maxlength={6} required value={codigo} onKeyUp={onChangeCodigo} onIonInput={(e: any) => setCodigo(e.target.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => { setShowToast(false); validar(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{textBotao}</IonButton>
        <div className="divisoria">
          <span></span>

        </div>
        <IonButton fill='clear' color="tertiary" disabled={desabilitarEnviarEmail} onClick={() => { email(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Enviar código por email</IonButton>
        {/* <div id="recaptcha-container"></div> */}
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle          
          message={textToast}
          duration={0}
          buttons={[
            {
              text: 'Fechar',
              role: 'cancel',
              handler: () => {
                setShowToast(false);
              }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default ValidarOtp;
