import { IonContent, IonPage, IonButton, IonLabel, IonSegment, IonSegmentButton, IonIcon, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonModal, useIonViewWillEnter, IonInfiniteScroll, IonInfiniteScrollContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonLoading, IonBadge, IonChip, useIonViewDidEnter, IonRefresher, IonRefresherContent } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { arrowBack, arrowBackCircleOutline, close, exit, handRight, list } from 'ionicons/icons';
import './Historico.css';
import { useHistory } from 'react-router-dom';
import iconVerified from "./imgs/certificado.svg";
import { listaHistoricoBeneficios, listaOperacao, requestService } from '../../../../../../Utils/Services';

function Historico() {

  const history = useHistory();
  const [arrayHistorico, setArrayHistorico] = useState<any>([]);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [beneficiopk, setBeneficiopk] = useState("");
  const [beneficiogsi2sk, setBeneficiogsi2sk] = useState("");

  const [showLoading, setShowLoading] = useState(true);


  useEffect(() => {
    carregarHistorico();
  }, []);

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
  });

  const loadItens = () => {

    //carregarHistorico();
    if (arrayHistorico === undefined || arrayHistorico.length === 0) {
      return (
        <IonCard key="primeira" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonCardHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Aqui aparece o histórico dos benefícios concedidos para os motoristas</IonCardTitle>
            <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonCardSubtitle>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
          </IonCardHeader>
          <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          </IonCardContent>
        </IonCard>
      );
    }

    return arrayHistorico.map((value: any, index: any, arr: any) =>











      //(new Date(value.dataValidado).toLocaleString('pt-BR').substring(0,10)===dia)?
      (index === 0 || (arr[index - 1] && new Date(value.dataValidado).toLocaleString('pt-BR').substring(0, 10) !== new Date(arr[index - 1].dataValidado).toLocaleString('pt-BR').substring(0, 10))) ?
        (<>
          <IonCard className={"card"} key={value.dataValidado + index.toString()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardHeader key={value.dataValidado} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className={"cabecalho"}>{new Date(value.dataValidado).toLocaleString('pt-BR').substring(0, 10) === new Date().toLocaleString('pt-BR').substring(0, 10) ? "Hoje" : new Date(value.dataValidado).toLocaleString('pt-BR').substring(0, 10)}</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          <IonCard key={"cartao" + value.id + index.toString()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardHeader key={value.id} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{value.motoristaNome}</IonCardTitle>
              <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Código validado: {value.id || "não identificado"}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Desconto: {value.desconto || "não identificado"}</IonLabel>
            </IonCardContent>
          </IonCard>
        </>
        ) :
        (
          <IonCard key={"cartao" + value.id + index.toString()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardHeader key={value.id} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{value.motoristaNome}</IonCardTitle>
              <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Código validado: {value.id || "não identificado"}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Desconto: {value.desconto || "não identificado"}</IonLabel>
            </IonCardContent>
          </IonCard>
        )
    );

  };

  function carregarHistorico() {
    const consultar = async () => {

      requestService(listaHistoricoBeneficios.url, {
        method: listaHistoricoBeneficios.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response", response);
          setArrayHistorico(response.Items);
          if (response.LastEvaluatedKey) {
            setBeneficiopk(response.LastEvaluatedKey.pk);
            setBeneficiogsi2sk(response.LastEvaluatedKey.gsi2sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setBeneficiopk("");
            setDesabilitarInfiniteScroll(true);
          }
          setShowLoading(false);
        },
        (error: any) => {
          setShowLoading(false);
          console.log("error", error);
          //history.replace("/login/");
        });
    };

    consultar();
  }

  async function carregarMaisHistorico($event: CustomEvent<void>) {

    await carregarMaisHistoricoChamada().then(() => {
      console.log("terminou resultados");
    });
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisHistoricoChamada() {

    requestService(listaHistoricoBeneficios.url + "?pk=" + beneficiopk + "&isk=" + beneficiogsi2sk, {
      method: listaHistoricoBeneficios.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        console.log(arrayHistorico.length);
        for (let i = 0; i < response.Items.length; i++) {
          console.log("resultados");
          arrayHistorico.push(response.Items[i]);
        }
        console.log(arrayHistorico.length);
        if (response.LastEvaluatedKey) {
          setBeneficiopk(response.LastEvaluatedKey.pk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setBeneficiopk("");
          setDesabilitarInfiniteScroll(true);
          console.log("finalizou");
        }
      },
      (error: any) => {
        console.log(error);
        if (error.status === 426) { // atualizar versao
          history.replace("/atualizar");
        }
        else if (error !== 408) {
          history.replace("/login/");
        }
      });
  }

  function doRefresh(event: any) {
    carregarHistorico();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon slot="icon-only" icon={list} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Histórico</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"Historico"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>

       
            {loadItens()}
       
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRefresherContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonRefresherContent>
        </IonRefresher>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisHistorico(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais itens do histórico..." placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles' />
      </IonContent>
    </IonPage>
  );
};

export default Historico;

