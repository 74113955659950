import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Monitor.css';
import { calendar, grid, storefront } from 'ionicons/icons';
import OperacoesFiltroLojas from '../Operacoes/OperacoesFiltroLojas';
import { urlBase } from '../../../../../Utils/Services';
import dayjs from 'dayjs';
import { Doughnut, Line } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Buffer } from 'buffer';

const Monitor: React.FC = () => {

  const history = useHistory();

  const [stateLojas, setStateLojas] = useState<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));

  const [stateDashboard, setStateDashboard] = useState<{}[]>([]);
  const [stateData, setStateData] = useState<Date>(new Date());
  const [showModalFiltroLojas, setShowModalFiltroLojas] = useState(false);
  const [stateMinutos, setStateMinutos] = useState(0);
  const [stateAutoReload, setStateAutoReload] = useState(false);
  const lojasRef = useRef<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));

  useIonViewDidEnter(() => {
    //CarregarDashboardV2();
    //setStateAutoReload(true);
    setShowModalFiltroLojas(true);
  });

  useIonViewWillLeave(() => setStateAutoReload(false));

  //foi utilizada referencia com o state pois ele nao atualiza o valor do state dentro do setInterval com useEffect
  useEffect(() => {
    lojasRef.current = stateLojas;
  })

  useEffect(() => {
    if (stateAutoReload) {
      const interval = setInterval(() => {
        CarregarDashboardV2(lojasRef.current);
      }, 30000); // 1 segundo = 1000 milissegundos

      // Limpeza do intervalo quando o componente for desmontado
      return () => clearInterval(interval);
    }
  }, [stateAutoReload]);

  async function CarregarDashboardV2(lojas?: any, dateParam?: Date) {

    //ativa o autoreload apos selecionar o filtro
    if (!stateAutoReload) {
      setStateAutoReload(true);
    }

    let lojasArray: any[] = [];
    let lojasCarregadas = (lojas || stateLojas).map(async (value: any) => {
      let paramFiltro = "Concluido";
      let paramLojas = Buffer.from(JSON.stringify(value)).toString('base64');
      let paramData
      if (dateParam) {
        paramData = dayjs(dateParam).format('YYYY-MM-DD');;
      }
      else {
        paramData = dayjs(stateData).format('YYYY-MM-DD');;
      }
      let paramPeriodo = "diario";

      let retorno: any = await BuscarOperacoesV2(paramFiltro, paramLojas, paramData, paramPeriodo);
      let responsePayload = await retorno.json();

      let retorno2: any = await BuscarOperacoesV2("Em", paramLojas, paramData, paramPeriodo);
      let responsePayload2 = await retorno2.json();

      if (responsePayload.Items && responsePayload.Items.length > 0) {
        responsePayload.Items.map((item: any) => lojasArray.push(item));
      }

      if (responsePayload2.Items && responsePayload2.Items.length > 0) {
        responsePayload2.Items.map((item: any) => lojasArray.push(item));
      }
    });

    Promise.all(lojasCarregadas).then(() => {
      setStateDashboard(lojasArray);
      let minutos = 0;
      lojasArray.map((item: any) => {
        let dataInicio = dayjs(item.dataInicio);
        let dataFim = dayjs(item.dataEncerramento || new Date());
        minutos += dataFim.diff(dataInicio, "minutes");
      });
      setStateMinutos(minutos);
    });
  }

  async function BuscarOperacoesV2(paramFiltro: any, paramLojas: any, paramData: any, paramPeriodo: string) {

    let fecthOptions: any = {
      method: urlBase.method,
      headers: [
        ["token", localStorage.getItem("token") || ""]
      ],
      credentials: 'include'
    };

    return await fetch(urlBase.url + "/operacoes?filtro=" + paramFiltro + "&lojas=" + paramLojas + "&data=" + paramData + "&periodo=" + paramPeriodo, fecthOptions);
  }

  return (
    <IonPage class="monitor" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot='start' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Monitor</IonTitle>
          <IonButtons slot="end" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} onClick={() => setShowModalFiltroLojas(true)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonIcon slot="icon-only" icon={storefront} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonRefresher slot="fixed" onIonRefresh={() => { }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRefresherContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonRefresherContent>
        </IonRefresher>

        <IonModal isOpen={showModalFiltroLojas} onDidDismiss={() => setShowModalFiltroLojas(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <OperacoesFiltroLojas lojas={stateLojas} setLojas={setStateLojas} setShowModalFiltroLojas={setShowModalFiltroLojas} updateDataFunction={CarregarDashboardV2} />
        </IonModal>

        <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCard placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCardHeader style={{ textAlign: 'center', background: '#1D3460' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel style={{ color: '#FFF' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações em andamento</IonLabel></IonCardHeader>
                <IonCardContent style={{ marginTop: '15px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{stateDashboard.filter((item: any) => item.status == "Em andamento").length}</IonLabel></IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCard placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCardHeader style={{ textAlign: 'center', background: '#1D3460' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel style={{ color: '#FFF' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações totais</IonLabel></IonCardHeader>
                <IonCardContent style={{ marginTop: '15px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{stateDashboard.length}</IonLabel></IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCard placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCardHeader style={{ textAlign: 'center', background: '#1D3460' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel style={{ color: '#FFF' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Duração média</IonLabel></IonCardHeader>
                <IonCardContent style={{ marginTop: '15px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{Math.round(stateMinutos / stateDashboard.length) + " minutos"}</IonLabel></IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid class='monitorListaEspera' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRow style={{ background: '#ff6a14' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCol size='1' style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>#</IonCol>
            <IonCol size='1' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Situação</IonCol>
            <IonCol size='7' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Fornecedor</IonCol>
            <IonCol size='1' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Chegada</IonCol>
            <IonCol size='1' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Duração</IonCol>
            <IonCol size='1' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Loja</IonCol>
          </IonRow>

          {stateDashboard.filter((item: any) => item.status == "Em andamento").sort((a: any, b: any) => ((a.dataInicio || "").toUpperCase() > (b.dataInicio || "").toUpperCase()) ? 1 : -1).slice(0, 10).map((item: any, indice: any) => {
            return (
              <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCol size='1' style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{indice + 1}</IonCol>
                <IonCol size='1' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{item?.anotacoes?.find((item: any) => { return item.anotacao.toLowerCase().includes('descarregar') }) ? "Conferência" : item.statusOperacao || "Aguardando"}</IonCol>
                <IonCol size='7' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{item?.notasAdicionais[0]?.razaoSocial || ""}</IonCol>
                <IonCol size='1' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{new Date(item.dataInicio).toLocaleTimeString()}</IonCol>
                <IonCol size='1' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{Math.round((+new Date() - +new Date(item.dataInicio)) / 1000 / 60)} min</IonCol>
                <IonCol size='1' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{item.clienteNomeInterno || ""}</IonCol>
              </IonRow>
            )
          })}

        </IonGrid>
      </IonContent>
    </IonPage >
  );

};
export default Monitor;