import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonToast, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, useIonViewDidEnter, isPlatform, useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './ValidarTokenMotorista.css';
import imgTopo from './imgs/topo.png';
import { useHistory } from 'react-router-dom';
import { arrowBack } from 'ionicons/icons';
import { confirmaTokenMotorista, requestService } from '../../../../../../Utils/Services';


const ValidarTokenMotorista: React.FC = () => {

  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Validar Código");
  const [codigo, setCodigo] = useState("");

  const inputRef = useRef<any>(null);

  useEffect(() => {
    inputRef.current.setFocus();
  });

  useIonViewDidEnter(() => {
    setBotaoAtivo(true);
    setCodigo("");
    setTextBotao("Validar código");

    inputRef.current.setFocus();
  });

  useIonViewWillLeave(() => {
    setShowToast(false);
  });

  const validar = () => {
    setBotaoAtivo(false);
    setTextBotao("Aguarde...");

    requestService(
      confirmaTokenMotorista.url.replace("{codigo}", codigo.toString() || ""),
      {
        method: confirmaTokenMotorista.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        setTextToast("Código validado com sucesso.");
        setShowToast(true);
        setCodigo("");
        setBotaoAtivo(true);
        setTextBotao("Validar código");
      },
      (error: any) => {
        setTextToast("Código inválido");
        setShowToast(true);
        setCodigo("");
        setBotaoAtivo(true);
        setTextBotao("Validar código");
      }
    );
  }

  function onChangeCodigo(e: any) {
    e.persist();
    e.preventDefault();
    setCodigo(e.target.value);

    if (e.keyCode === 13) {
      if (botaoAtivo) validar();
    }
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>VALIDE O CÓDIGO DO MOTORISTA</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"ValidarTokenMotorista"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonLabel className="titulo" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Insira o <strong>código</strong> gerado pelo <strong>Motorista</strong>:</IonLabel>
        <div className="input">
          <span>Código</span>
          <IonInput ref={(ref) => inputRef.current = ref} maxlength={6} required value={codigo} onKeyUp={onChangeCodigo} onIonInput={(e: any) => setCodigo(e.target.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => { setShowToast(false); validar(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{textBotao}</IonButton>
        <div className="divisoria">
          <span></span>

        </div>
        <IonToast
          isOpen={showToast}
          position="middle"
          message={textToast}
          duration={0}
          buttons={[
            {
              text: 'Fechar',
              role: 'cancel',
              handler: () => {
                setShowToast(false);
              }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default ValidarTokenMotorista;
