import { IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import dayjs from 'dayjs';
import { arrowBack, calendar } from 'ionicons/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { useHistory } from 'react-router-dom';
import { urlBase } from '../../../../../Utils/Services';
import OperacoesTable from './OperacoesTable';
import './Relatorio.css';
import { Buffer } from 'buffer';


const Relatorio: React.FC = () => {

  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [InstrucoesPages, setInstrucoesPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayInstrucoes, setarrayInstrucoes] = useState<any>([]);
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [instrucoes, setInstrucoes] = useState("");
  const [showExcluirInstrucoes, setShowExcluirInstrucoes] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [chamarServico, setChamarServico] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showExcluding, setShowExcluding] = useState(false);
  const [imagem, setImagem] = useState<any>();
  const [stateLoja, setStateLoja] = useState<any>();
  const [stateLojaNome, setStateLojaNome] = useState<any>();
  const [stateData, setStateData] = useState<any>(new Date().toISOString());
  const [stateLastEvaluatedKey, setStateLastEvaluatedKey] = useState<any>();
  const [stateOperacoes, setStateOperacoes] = useState<any>([]);
  const [stateCarregado, setStateCarregado] = useState<boolean>(false);
  const [stateStatus, setStateStatus] = useState<any>("Concluido");
  const [statePeriodo, setStatePeriodo] = useState<any>("diario");

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";
  });

  function tempoCorrido(horario: Date, dataFim?: Date) {
    var g = Math.ceil(((dataFim ? dataFim.getTime() : Date.now()) - horario.getTime()) / 1000 / 60);
    var tempoApurado = Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + 'min';
    return tempoApurado !== "0h00min" ? tempoApurado : "0h01min";
  }

  async function carregarOperacoesSegmentada(filtro: any, lojasParam: any, data: string) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let dataFormatada = dayjs(stateData).format('YYYY-MM-DD');

      let urlFinal;
      urlFinal = urlBase.url + "/operacoes?filtro=" + filtro + "&lojas=" + Buffer.from(JSON.stringify(lojasParam)).toString('base64') + "&data=" + dataFormatada + "&periodo=" + statePeriodo;

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      if (resp) {
        let res = await resp.json();


        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setStateLastEvaluatedKey(res.LastEvaluatedKey);
        }
        else {
          setStateLastEvaluatedKey(null);
        }

        if (res && res.Items) {
          return res.Items;
        }
        else {
          return null;
        }
      }
    };
    return await consultar();
  }

  async function carregarDados(situacao: any, cliente: any, data: any, exportFunction?: any) {
    let loadResult = await carregarOperacoesSegmentada(situacao, cliente, data);
    let arrayOperacoes: any[] = [];

    arrayOperacoes.push(['#', 'NF', 'Fornecedor', 'CNPJ', 'Tipo Entrega', 'Chegada', 'Término', 'Duração', 'Status Entrega', 'Motorista', 'Placa Veículo']);

    let contador = 0;

    loadResult.map((valor: any, index: any) => {
      if (valor.notasAdicionais.length > 0) {
        valor.notasAdicionais.map((nota: any) => {
          contador++;
          arrayOperacoes.push(
            [
              contador,
              nota.numeroNotaFiscal,
              nota.razaoSocial,
              nota.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
              valor.tipoCarga || "",
              dayjs(valor.dataInicio).format('DD/MM/YYYY HH:mm') || "",
              dayjs(valor.dataEncerramento).format('DD/MM/YYYY HH:mm') || "",
              tempoCorrido(new Date(valor.dataInicio), valor.dataEncerramento ? new Date(valor.dataEncerramento) : new Date()) || "",
              valor.status || "",
              valor.motoristaNome || "",
              valor.veiculo || ""
            ]);
        });
      }
      else {
        contador++;
        arrayOperacoes.push(
          [
            contador,
            "Sem nota informada",
            "Não aplicável",
            "Não aplicável",
            valor.tipoCarga || "",
            dayjs(valor.dataInicio).format('DD/MM/YYYY HH:mm') || "",
            dayjs(valor.dataEncerramento).format('DD/MM/YYYY HH:mm') || "",
            tempoCorrido(new Date(valor.dataInicio), valor.dataEncerramento ? new Date(valor.dataEncerramento) : new Date()) || "",
            valor.status || "",
            valor.motoristaNome || "",
            valor.veiculo || ""
          ]);
      }
    });

    setStateOperacoes(arrayOperacoes);

    if (exportFunction) {
      exportFunction(arrayOperacoes);
    }

  }

  async function gerarRelatorioPDF(arrayOperacoes: any) {

    pdfMake.vfs = pdfFonts.vfs;

    const reportTitle: any = [{
      text: 'Operações',
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45]  // left, top, riight, bottom
    }];

    const reportDetails: any = statePeriodo == 'diario' ? [
      { text: stateLoja, style: 'subheader' },
      { text: 'Data: ' + new Date(stateData || "").toLocaleString("pt-BR").substring(0, 10), style: 'subheader' },
      {
        table: {
          body: arrayOperacoes

          /*[
            ['Loja', 'NF', 'Fornecedor', 'CNPJ', 'Data Entrega', 'Tipo Entrega', 'Chegada', 'Término', 'Duração', 'Status Entrega', 'Motorista', 'Placa Veículo'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
            ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
          ]*/
        },
        layout: {
          fillColor: function (rowIndex: any, node: any, columnIndex: any) {
            return (rowIndex == 0) ? '#FFA508' : (rowIndex % 2 === 0) ? '#CCCCCC' : null;
          }
        },
        style: 'padrao'
      }
    ] :
      [
        { text: stateLoja, style: 'subheader' },
        { text: 'Mês: ' + new Date(stateData || "").toLocaleString("pt-BR").substring(3, 10), style: 'subheader' },
        {
          table: {
            body: arrayOperacoes

            /*[
              ['Loja', 'NF', 'Fornecedor', 'CNPJ', 'Data Entrega', 'Tipo Entrega', 'Chegada', 'Término', 'Duração', 'Status Entrega', 'Motorista', 'Placa Veículo'],
              ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
              ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
              ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
              ['Sample value 2', 'Sample value 2', 'Sample value 2', 'Sample value 1', 'Sample value 2', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3', 'Sample value 3'],
            ]*/
          },
          layout: {
            fillColor: function (rowIndex: any, node: any, columnIndex: any) {
              return (rowIndex == 0) ? '#FFA508' : (rowIndex % 2 === 0) ? '#CCCCCC' : null;
            }
          },
          style: 'padrao'
        }
      ];

    const reportFooter: any = [];

    function rodape(currentPage: any, pageCount: any) {
      return [
        {
          text: currentPage + ' / ' + pageCount,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0]
        }
      ]
    }

    const docDefinitions: any = {
      pageSize: 'A4',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      pageMargin: [15, 50, 15, 40],

      //header: [reportTitle],
      content: [reportDetails],
      footer: rodape,
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        padrao: {
          fontSize: 8
        }
      }
    }

    pdfMake.createPdf(docDefinitions).download();
    //pdfMake.createPdf(docDefinitions).open();
    //pdfMake.createPdf(docDefinitions).print();
  }

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  const funcConfirm = () => {
    datetime.current?.confirm(true);
  };

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton onClick={() => { setStateOperacoes([]); setStateCarregado(false); history.goBack(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonIcon slot="icon-only" icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></IonButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Relatório Analítico</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div style={{ textAlign: "center" }} hidden={stateCarregado}>
          <IonItem hidden={false} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Período</IonLabel>
            <IonSelect value={statePeriodo} onIonChange={(e) => { setStatePeriodo(e.detail.value); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonSelectOption value={'diario'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Diário</IonSelectOption>
              <IonSelectOption value={'mensal'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Mensal</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem hidden={statePeriodo != 'diario'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Data</IonLabel>
            <IonDatetimeButton slot='end' datetime="datetime" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonDatetimeButton>
            <IonIcon slot='end' icon={calendar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonItem>
          <IonItem hidden={statePeriodo == 'diario'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Mês</IonLabel>
            <IonDatetimeButton slot='end' datetime="datetimemonth" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonDatetimeButton>
            <IonIcon slot='end' icon={calendar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonItem>
          <IonItem hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Loja</IonLabel>
            <IonSelect placeholder='Selecione a loja' onIonChange={(e) => { setStateLoja(e.detail.value.split('|')[1]); setStateLojaNome(e.detail.value.split('|')[0]); } } onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                <IonSelectOption value={valor.clienteLojaId + '|' + valor.clienteLojaNome} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>


          <p></p>
          <IonButton onClick={async () => { console.log('loja', stateLoja, stateLojaNome); if (stateLoja == undefined) return; setStateCarregado(true); await carregarDados(stateStatus, stateLojaNome, stateData); } } expand="block" type="submit" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Buscar</IonButton>
        </div>
        <div style={{ textAlign: "center" }} hidden={!stateCarregado}>
          <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{stateLoja}</IonLabel>
          </IonItem>
          <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel hidden={statePeriodo != 'diario'} style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Data: {dayjs(stateData).format("DD/MM/YYYY")}</IonLabel>
            <IonLabel hidden={statePeriodo != 'mensal'} style={{ textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Mês: {dayjs(stateData).format("MM/YYYY")}</IonLabel>
          </IonItem>
          <p></p>
          <IonButton onClick={() => gerarRelatorioPDF(stateOperacoes)} type="button" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Gerar PDF</IonButton>
          <CSVLink data={stateOperacoes} separator={";"}><IonButton type="button" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Gerar CSV</IonButton></CSVLink>
          <IonButton onClick={() => { setStateOperacoes([]); setStateCarregado(false); } } type="button" fill='solid' color='tertiary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Limpar</IonButton>
          <p></p>
          <OperacoesTable model={stateOperacoes}></OperacoesTable>
        </div>
        <p></p>
        <IonModal keepContentsMounted={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonDatetime ref={datetime} id="datetime" presentation='date' value={stateData} onIonChange={(e) => { setStateData(e.detail.value); funcConfirm(); } } min='2022-03-01' max={dayjs(new Date()).toISOString()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonDatetime>
        </IonModal>
        <IonModal keepContentsMounted={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonDatetime showDefaultButtons={true} id="datetimemonth" presentation='month-year' value={stateData} onIonChange={(e) => { setStateData(e.detail.value); } } min='2022-03-01' max={dayjs(new Date()).toISOString()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonDatetime>  
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Relatorio;