import { IonAlert, IonButton, IonButtons, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonItemDivider, IonLabel, IonModal, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import ptBR from 'date-fns/locale/pt-BR';
import { add, calendar, download, refresh, storefront, trash } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { excluiAgendamento, finalizaAgendamento, listaAgendamentos, listaOperacao, requestService } from '../../../../../Utils/Services';
import AgendamentosCad from '../AgendamentosCad/AgendamentosCad';
import './Agendamentos.css';
import { Buffer } from 'buffer';

const Agendamentos: React.FC = () => {

  const history = useHistory();
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [operacaopk, setOperacaopk] = useState("");
  const [operacaosk, setOperacaosk] = useState("");
  const [operacaogsi2sk, setOperacaogsi2sk] = useState("");
  const [perfil, setPerfil] = useState(localStorage.getItem("perfil"));
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [pk, setPk] = useState("");
  const [showExcluirAgendamento, setShowExcluirAgendamento] = useState(false);
  const [showFinalizarAgendamento, setShowFinalizarAgendamento] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [agendamento, setAgendamento] = useState<any>();
  const [pesquisa, setPesquisa] = useState<any>(new Date().toLocaleString('pt-BR').substring(0, 10));
  const [statusAgendamento, setStatusAgendamento] = useState<string>("ag");
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");
  const [stateAcessos, setStateAcessos] = useState<any>(window?.localStorage?.getItem("acessos") ? JSON.parse(window?.localStorage?.getItem("acessos") || "") : "");

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";

  });

  function cnpj_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function toISOFormat(dateString: string) {
    // Dividimos a data em dia, mês e ano:
    const [DD, MM, YYYY] = dateString.split('/');


    // Retornamos a data formatada em um padrão compatível com ISO:
    return `${YYYY}-${MM}-${DD}`;
  }

  function formatarData(data: Date) {
    return (adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + "/" + data.getFullYear().toString());
  }

  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const loadItens = () => {
    if (!chamadaRealizada) {
      setChamadaRealizada(true);
      carregarAgendamentos("ag");
    }

    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonItemDivider style={{ color: '#333' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonLabel style={{ width: '100%', textAlign: 'center', fontSize: '20px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Nenhum agendamento cadastrado nesta data.</IonLabel>
        </IonItemDivider>
      );
    }

    if (stateAcessos?.agendamento == 'edicao') {
      return arrayOperacoes.map((value: any, index: any, arr: any) => (
        <>
          {(index == 0 || value.horaAgendamento != arr[index - 1].horaAgendamento) ?
            (<IonCol size='12' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonItemDivider style={{ background: '#CCC', color: '#333' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel style={{ width: '100%', textAlign: 'left', fontSize: '22px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{value.horaAgendamento}</IonLabel></IonItemDivider>
            </IonCol>) : <></>
          }
          <IonCol size='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='4' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonItem style={{ minWidth: '300px' }} lines='full' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonNote slot='start' style={{ alignSelf: 'center', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px' }} color='secondary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><div style={{ display: 'block', justifyContent: 'center' }}><div style={{ fontSize: '10px', width: '100px' }}>Número do Agendamento</div><div style={{ fontSize: '24px', fontWeight: 'bold' }}>{value.sequenciaAgendamento}</div></div></IonNote>
              <IonLabel class="ion-text-wrap" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {cnpj_mask(value.cnpj)}
                <p style={{ fontSize: '10px' }}><b>Pedidos:</b> {value.numeroPedido || 'não informado'}</p>
                <p style={{ fontSize: '10px' }}><b>Descrição:</b> {value.descricaoAgendamento || 'não informado'}</p>
                <p style={{ fontSize: '10px' }}><b>Email:</b> {value.email || 'não informado'}</p>
              </IonLabel>
              <IonIcon icon={trash} color='danger' slot="end" onClick={() => { setPk(value.pk); setMensagem("Deseja realmente excluir o agendamento?"); setShowExcluirAgendamento(true); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            </IonItem>
          </IonCol>
          {(value.horaAgendamento != arr[index + 1]?.horaAgendamento) ?
            <></> : <></>}
        </>
      )
      );
    }
    else {
      return arrayOperacoes.map((value: any, index: any, arr: any) =>
        <div>
          {(index == 0 || value.horaAgendamento != arr[index - 1].horaAgendamento) ?
            <IonItemDivider style={{ background: '#CCC', color: '#333' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><IonLabel style={{ width: '100%', textAlign: 'left', fontSize: '22px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{value.horaAgendamento}</IonLabel></IonItemDivider> : <></>}

          <IonItem style={{ overflow: 'visible' }} lines='full' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonNote slot='start' style={{ alignSelf: 'center', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px' }} color='secondary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><div style={{ display: 'block', justifyContent: 'center' }}><div style={{ fontSize: '10px', width: '100px' }}>Número do Agendamento</div><div style={{ fontSize: '24px', fontWeight: 'bold' }}>{value.sequenciaAgendamento}</div></div></IonNote>
            <IonLabel class="ion-text-wrap" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <p style={{ color: '#000000', fontWeight: 'bold' }}>{cnpj_mask(value.cnpj)}</p>
              <p>{value.descricaoAgendamento}</p>
              <p style={{ fontSize: '12px' }}>{value.email ? 'emails: ' + value.email : <></>}</p>
            </IonLabel>
          </IonItem>
        </div>
      );
    }
  }

  function carregarAgendamentos(filtro: string, data?: string, loja?: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }
      console.log("filtro", filtro);
      console.log("pesquisa", pesquisa);

      let montaPesquisa = ''
      if (pesquisa && pesquisa != undefined) {
        montaPesquisa = toISOFormat(data || pesquisa)
      }
      console.log("loja || lojas", loja || lojas);
      const resp = await fetch(listaAgendamentos.url + "?filtro=" + filtro + "&valor=" + montaPesquisa + "&lojas=" + Buffer.from(loja || lojas).toString("base64"),
        {
          method: listaAgendamentos.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      let agendamentos: any;
      try {
        agendamentos = await resp.json();
      } catch (e: any) { return; }
      console.log("agendamentos", agendamentos);
      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {
        //setArrayOperacoes(agendamentos.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1));
        setArrayOperacoes(agendamentos.Items);
        if (agendamentos.LastEvaluatedKey) {
          setOperacaopk(agendamentos.LastEvaluatedKey.pk);
          setOperacaosk(agendamentos.LastEvaluatedKey.sk);
          setOperacaogsi2sk(agendamentos.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setDesabilitarInfiniteScroll(true);
        }
      }
    }
    consultar();
  }

  async function carregarMaisAgendamentos($event: CustomEvent<void>) {

    await carregarMaisAgendamentosChamada().then(() => {
      console.log("terminou resultados");//($event.target as HTMLIonInfiniteScrollElement).complete();
    });

    ($event.target as HTMLIonInfiniteScrollElement).complete();


  }

  async function carregarMaisAgendamentosChamada() {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montaPesquisa = ''
    if (pesquisa && pesquisa != undefined) {
      montaPesquisa = toISOFormat(pesquisa)
    }

    const res: Response = await fetch(listaAgendamentos.url + "?filtro=" + statusAgendamento + "&valor=" + montaPesquisa + "&isk=" + operacaogsi2sk + "&pk=" + operacaopk + "&sk=" + operacaosk + "&lojas=" + Buffer.from(lojas).toString("base64"),
      {
        method: listaOperacao.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (operacoes) => {
        console.log(arrayOperacoes.length);
        for (let i = 0; i < operacoes.Items.length; i++) {
          console.log("resultados");
          arrayOperacoes.push(operacoes.Items[i]);
        }
        console.log(arrayOperacoes.length);
        if (operacoes.LastEvaluatedKey) {
          // console.log("entrou aqui");
          setOperacaopk(operacoes.LastEvaluatedKey.pk);
          setOperacaosk(operacoes.LastEvaluatedKey.sk);
          setOperacaogsi2sk(operacoes.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setOperacaosk("");
          setOperacaogsi2sk("");
          setDesabilitarInfiniteScroll(true);
        }
        //($event.target as HTMLIonInfiniteScrollElement).complete();
      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");
  }

  async function closeModal() {
    setAgendamento(null);
    setShowDetalhes(false);
    carregarAgendamentos(statusAgendamento);
  }

  async function closeModalSimple() {
    setAgendamento(null);
    setShowDetalhes(false);
  }

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  function doRefresh(event: any) {
    // console.log('Begin async operation');
    //carregarOperacoes();
    // console.log("refresh" + statusOperacao);
    carregarAgendamentos(statusAgendamento);
    setTimeout(() => {
      // console.log('Async operation has ended');
      event.detail.complete();
    }, 1200);
  }


  async function handleSegmentChange(e: any) {
    setStatusAgendamento(e.detail.value);
    await carregarAgendamentos(e.detail.value);
  }


  function onChangeData(e: any) {
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 10);
      setPesquisa(e.target.value.substring(0, 10));
    }

    if (e.target.value.length > 10) {
      e.target.value = e.target.value.substring(0, 10);
      setPesquisa(e.target.value.substring(0, 10));
      return;
    }

    let val = e.target.value;
    var valor = val.replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{2})(\d)/, '$1/$2');

    setPesquisa(valor);

  }


  return (
    <IonPage id='paginaAgendamentos' className='paginaAgendamentos' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div style={{ position: 'relative' }}>
          <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonIcon slot="icon-only" icon={calendar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Agenda</IonTitle>
            <div style={{ position: 'absolute', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0, width: 'max-content' }}>
              <IonChip outline style={{ background: 'white', paddingLeft: 0, paddingRight: 0, height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonChip outline style={{ background: 'white', border: 'none', height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonIcon icon={storefront} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
                  <IonSelect name='Lojas' placeholder='Selecione as lojas' title='Lojas' value={lojas} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); carregarAgendamentos(statusAgendamento, undefined, e.detail.value); } } onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonChip>
                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>|</IonLabel>
                <IonChip outline style={{ background: 'white', border: 'none', height: '46px', borderRadius: '32px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <div>
                    <DatePicker
                      onFocus={e => e.target.blur()}
                      dateFormat="dd/MM/yyyy"
                      locale={ptBR} portalId='paginaAgendamentos'
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date || new Date());
                        setPesquisa((date || new Date()).toLocaleString('pt-BR').substring(0, 10));
                        carregarAgendamentos(statusAgendamento || "ag", (date || new Date()).toLocaleString('pt-BR').substring(0, 10));
                      }}
                    />
                  </div>
                  <IonIcon icon={calendar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
                </IonChip>
              </IonChip>
            </div>
          </IonToolbar>

        </div>
      </IonHeader>
      <IonContent className="agendamentoscontent" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >

        <IonRefresher slot="fixed" onIonRefresh={doRefresh} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRefresherContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonRefresherContent>
        </IonRefresher>

        <IonModal isOpen={showDetalhes} onDidDismiss={() => { setShowDetalhes(false); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <AgendamentosCad agendamento={agendamento} closeAction={closeModal} closeActionSimple={closeModalSimple}></AgendamentosCad>
        </IonModal>

        <IonFab vertical="top" horizontal="start" slot="fixed" style={{ top: '-15px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonFabButton color="tertiary" onClick={() => { carregarAgendamentos(statusAgendamento); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon color="light" icon={refresh} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab hidden={stateAcessos?.agendamento != 'edicao' /*perfil == "Usuário"*/} vertical="top" horizontal="end" slot="fixed" style={{ top: '-10px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonFabButton color="tertiary" onClick={() => setShowDetalhes(true)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon color="light" icon={add} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab vertical="bottom" horizontal="end" slot="fixed" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}  >
          <IonFabButton color="tertiary" onClick={() => {
            history.push("./relatorioagendamentos");
          } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon color="light" icon={download} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonItemDivider style={{ background: '#FFF', height: '60px', borderBottom: 'solid', borderColor: '#FFA300' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonLabel style={{ width: '100%', textAlign: 'center', color: '#000', fontSize: '18px', fontWeight: 'bold' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>HORÁRIOS</IonLabel>
        </IonItemDivider>

        <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ><IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {loadItens()}</IonRow>
        </IonGrid>

        <div>
          <IonInfiniteScroll
            threshold="100px"
            onIonInfinite={(e: CustomEvent<void>) => carregarMaisAgendamentos(e)}
            position="bottom"
            disabled={desabilitarInfiniteScroll} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais agendammentos..." placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInfiniteScrollContent>
          </IonInfiniteScroll>

          <IonAlert
            isOpen={showExcluirAgendamento}
            onDidDismiss={() => setShowExcluirAgendamento(false)}
            header={'Exclusão de agendamento'}
            message={mensagem}
            buttons={[
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Confirmar',
                cssClass: 'primary',
                handler: () => {
                  requestService(excluiAgendamento.url.replace("{pk}", pk.replaceAll("#", "%23")), {
                    method: excluiAgendamento.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include'
                  },
                    history,
                    (response: any) => {
                      carregarAgendamentos(statusAgendamento);
                      setTextToast(response.message);
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
              }
            ]}
          />


          <IonAlert
            isOpen={showFinalizarAgendamento}
            onDidDismiss={() => setShowFinalizarAgendamento(false)}
            header={'Finalizar agendamento'}
            message={mensagem}
            buttons={[
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Confirmar',
                cssClass: 'primary',
                handler: () => {
                  requestService(finalizaAgendamento.url.replace("{pk}", pk.replaceAll("#", "%23")), {
                    method: finalizaAgendamento.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include'
                  },
                    history,
                    (response: any) => {
                      carregarAgendamentos(statusAgendamento);
                      setTextToast(response.message);
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
              }
            ]}
          />

          <IonAlert
            isOpen={showSucesso}
            onDidDismiss={() => setShowSucesso(false)}
            header={'Sucesso'}
            message={textToast}
            buttons={['OK']}
          />

          <IonAlert
            isOpen={showErro}
            onDidDismiss={() => setShowErro(false)}
            header={'Erro'}
            message={textToast}
            buttons={['OK']}
          />
        </div>
        <div style={{ height: '60px' }}></div>

      </IonContent>
    </IonPage>
  );
};

export default Agendamentos;
