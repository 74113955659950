import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonToast } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './Cadastro.css';
import imgTopo from './imgs/topo.png';
import { Link, useHistory } from 'react-router-dom';
import { requestService, usuarioCadastrar } from '../../../Utils/Services';


const Cadastro: React.FC = () => {

  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;
  const history = useHistory();
  const campoTelefone = useRef<any>(null);
  const [telefone, setTelefone] = useState("");
  const [cpf, setCPF] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const cadastrar = () => {
    const objectRequest = {
      cpf: cpf.replace("-","").replace(".","").replace(".",""),
      telefone: "55"+telefone.replace("(","").replace(")","").replace(" ","").replace("-","")
    }
    requestService(
      usuarioCadastrar.url,
      {
        method: usuarioCadastrar.method,
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      history,
      (response:any) => {
        console.log(response);
        if(response){
          window.localStorage.setItem("cpf", cpf.replace("-","").replace(".","").replace(".",""));
          window.localStorage.setItem("telefone", "55"+telefone.replace("(","").replace(")","").replace(" ","").replace("-",""));
          history.replace("/validar-celular");
        }else if(response.status === 400){
          
        }
      },
      (error:any) => {
        console.log(error);
        setTextToast("Cadastro já existente");
        setShowToast(true);
      }
    )
  }

  function onChangeCPF(e: any){

    //console.log(cpf.length);
    //console.log(e.target.value.length);
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE){
      e.target.value = e.target.value.substring(0,14);
      setCPF(e.target.value.substring(0,14));
      campoTelefone.current.setFocus();
    }
    
    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0,14);
      setCPF(e.target.value.substring(0,14));
      return;
    }

    let val = e.target.value;
    var cpfval=val.replace(/\D/g,"")
    cpfval=cpfval.replace(/(\d{3})(\d)/,"$1.$2")
    cpfval=cpfval.replace(/(\d{3})(\d)/,"$1.$2")
    cpfval=cpfval.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any){

    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE){
      e.target.value = e.target.value.substring(0,15);
      setTelefone(e.target.value.substring(0,15));
      cadastrar();
    }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0,15);
      setTelefone(e.target.value.substring(0,15));
      return;
    }

    console.log(telefone.length);
    console.log(e.target.value.length);

    let replaceAt = function(str:any, index:any, replacement:any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";
    
    let val = e.target.value;
    
    let i = 1;
    if(telefone.length > 0){
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if(val.length > telefone.length){

      for(; i < form.length; i++){
        if(j<val.length){

          form = replaceAt(form, i, val.charAt(j))

          if(form.charAt(i+1) === '(' || form.charAt(i+1) === ')' || form.charAt(i+1) === '-'){
            if(val.charAt(j+1) !== form.charAt(i+1)){
              if(form.charAt(i+1) === ')'){
                i++;
              }
              i++;
              aumentou = true;
              j++;
            }else{
              j++
            }
          }else{
            j++;
          }
        }else{
          break;
        }
      }
    }else{
      if(telefone.length === 1 || telefone.length === 11){
        form = val.substr(0, val.length-1);
      }else if(telefone.length === 5){
        form = val.substr(0, val.length-2);
      }else{
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonContent className={"Cadastro"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div id="blocoTopo"></div>
        <img src={imgTopo} alt="Logo"/>
        <IonLabel className="titulo" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cadastre-se</IonLabel>
        <div className="input">
          <span>CPF</span>
          <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
        </div>
        <div className="input">
          <span>Telefone</span>
          <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
        </div>
        <IonButton onClick={cadastrar} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cadastrar</IonButton>
        <IonLabel className="jaTem" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Já tem uma conta? <Link to="/login">Entrar</Link></IonLabel>
        <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Cadastro;
