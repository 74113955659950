import '@capacitor-community/http';
import { IonAlert, IonBadge, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonListHeader, IonNote, IonPage, IonTitle, IonToggle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { albums, analytics, barcode, barcodeOutline, bookmarks, bookmarksOutline, callOutline, car, card, ellipsisHorizontal, exit, exitOutline, help, idCardOutline, information, informationCircle, informationCircleOutline, key, keyOutline, lockClosed, lockClosedOutline, mailOutline, menu, notificationsOutline, person, personOutline, qrCode, qrCodeOutline, statsChart, statsChartOutline, thumbsUp, thumbsUpOutline, toggleOutline, tv, tvOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deslogar, requestService } from '../../../../../Utils/Services';
import './Conta.css';

const Conta: React.FC = () => {

    const history = useHistory();
    const [showToast2, setShowToast2] = useState(false);
    const [stateAvisarNovasOperacoes, setSatetAvisarNovasOperacoes] = useState(JSON.parse(window.localStorage.getItem("notificacoes") || "false"));
    const toggleAvisar = useRef<any>(null);

    useIonViewDidEnter(() => {
        document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
    });

    function formatPhoneNumber(value: any) {
        if (!value) {
            return "";
        }

        const phoneNumber = value.replace(/\D/g, '');

        if (phoneNumber.length < 12) {
            return phoneNumber;
        }

        const formattedPhoneNumber = phoneNumber.replace(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/, '+$1 ($2) $3-$4');
        return formattedPhoneNumber;
    }

    function formatCPF(value: any) {
        if (!value) {
            return "";
        }

        const cpf = value.replace(/\D/g, '');

        if (cpf.length !== 11) {
            return cpf;
        }

        const formattedCPF = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        return formattedCPF;
    }

    const verifyNotificationPermission = async (checkForUpdates: any) => {

        // Solicitar permissão para notificações
        if (Notification.permission !== "granted") {

            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                setSatetAvisarNovasOperacoes(checkForUpdates);
                window.localStorage.setItem("notificacoes", JSON.stringify(checkForUpdates));
            } else {
                if (toggleAvisar.current && toggleAvisar.current.checked) {
                    toggleAvisar.current.checked = false;
                }
                else {
                    alert("Precisa autorizar as notificações no navegador.");
                }
            }
        }
        else {
            setSatetAvisarNovasOperacoes(checkForUpdates);
            window.localStorage.setItem("notificacoes", JSON.stringify(checkForUpdates));
        }
    };

    return (
        <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonIcon slot="icon-only" icon={person} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{localStorage.getItem("nome") || "Perfil"}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className="conta" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Acesso</IonLabel>
                </IonListHeader>
                <IonItem style={{ alignItems: 'start' }} lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={idCardOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <p style={{ color: 'black', fontSize: '18px' }} hidden={window.localStorage.getItem("cpf") == undefined}>{formatCPF(window.localStorage.getItem("cpf"))}</p>
                        <p hidden={window.localStorage.getItem("nome") == undefined}>{window.localStorage.getItem("nome")}</p>
                        <p hidden={window.localStorage.getItem("email") == undefined}>{window.localStorage.getItem("email")}</p>
                        <p hidden={window.localStorage.getItem("telefone") == undefined}>{formatPhoneNumber(window.localStorage.getItem("telefone"))}</p>
                    </IonLabel>
                    <IonButton style={{ marginTop: '12px' }} slot='end' color="medium" onClick={() => { setShowToast2(true); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        sair
                    </IonButton>
                    <IonAlert
                        isOpen={showToast2}
                        onDidDismiss={() => setShowToast2(false)}
                        cssClass='my-custom-class'
                        header={'Sair do Saidamax'}
                        message={'Deseja realmente <strong>desconectar</strong> de sua conta?'}
                        buttons={[
                            {
                                text: 'Cancelar',
                                role: 'cancel',
                            },
                            {
                                text: 'Sair',
                                cssClass: 'primary',
                                handler: () => {
                                    requestService(deslogar.url, {
                                        method: deslogar.method,
                                        headers: [["token", localStorage.getItem("token") || ""]],
                                        credentials: 'include'
                                    },
                                        history,
                                        (response: any) => {
                                            localStorage.clear();
                                            history.replace("/login/");
                                        },
                                        (error: any) => {
                                            localStorage.clear();
                                            history.replace("/login/");
                                        });
                                }
                            }
                        ]}
                    />
                </IonItem>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações</IonListHeader>
                <IonItem hidden={false} lines="none" button routerLink="/cliente/operacoessalvas" routerDirection="forward" onClick={() => { }} detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={bookmarksOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Salvas</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/contaalt" routerDirection="forward" onClick={() => { }} detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={informationCircleOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Instruções ao motorista</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/instrucoes" routerDirection="forward" onClick={() => { }} detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={thumbsUpOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Benefícios ao motorista</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/qrcode" routerDirection="forward" onClick={() => { }} detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={qrCodeOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>QR Code para motoristas</IonLabel>
                </IonItem>
                <IonItem lines="none" onClick={() => { }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={notificationsOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Avisar novas operações</IonLabel>
                    <IonToggle ref={toggleAvisar} checked={stateAvisarNovasOperacoes} onIonChange={async (e: any) => { await verifyNotificationPermission(e.detail.checked); /*window.localStorage.setItem("notificacoes", JSON.stringify(e.detail.checked)); console.log("evento", e.detail.checked); console.log(JSON.stringify(e.detail.checked))*/ }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonToggle>
                </IonItem>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Relatórios</IonListHeader>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/relatorio" routerDirection="forward" onClick={() => { }} detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={statsChartOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Analítico</IonLabel>
                </IonItem>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/relatorioboleto" routerDirection="forward" onClick={() => { }} detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={barcodeOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Boletos</IonLabel>
                </IonItem>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Online</IonListHeader>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/painel" routerDirection="forward" detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={analytics} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Painel</IonLabel>
                </IonItem>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/monitor" routerDirection="forward" detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={tvOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Monitor</IonLabel>
                </IonItem>
                <IonItem lines="none" hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={tv} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Nova tela de operações</IonLabel>
                    <IonToggle checked={localStorage.getItem("operacoesNovo") == '1' ? true : false} onIonChange={(e) => { e.detail.checked ? window.localStorage.setItem("operacoesNovo", '1') : window.localStorage.setItem("operacoesNovo", '0'); window.location.reload(); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/instrucoes" routerDirection="forward" onClick={() => { }} detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={car} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Veículos</IonLabel>
                </IonItem>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={statsChart} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Relatórios</IonLabel>
                </IonItem>
                <IonListHeader lines="full" hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Assinatura</IonListHeader>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={card} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Forma de pagamento</IonLabel>
                </IonItem>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={albums} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Histórico de pagamentos</IonLabel>
                </IonItem>
                {/* 
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Integrações</IonListHeader>
                <IonItem lines="none" button onClick={() => { }} detail hidden={false} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={keyOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Chaves de acessos</IonLabel>
                </IonItem>
                */}
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Informações</IonListHeader>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={help} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Ajuda</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/sobre" routerDirection="forward" onClick={() => { }} detail hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={information} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Sobre</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/termospolitica" routerDirection="forward" onClick={() => { }} detail placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={lockClosedOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Privacidade e termos de uso</IonLabel>
                </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon color='medium' slot="start" icon={informationCircleOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Versão</IonLabel>
                    <IonNote slot="end" className="footeritemnote" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>2.7.0</IonNote>
                </IonItem>
            </IonFooter>
        </IonPage>
    );
};

export default Conta;
