import { IonContent, IonPage, IonFab, IonFabButton, IonButton, IonLabel, IonSegment, IonSegmentButton, IonItem, IonIcon, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonModal, useIonViewDidEnter, useIonViewWillLeave, IonInfiniteScroll, IonInfiniteScrollContent, IonToast, useIonViewDidLeave, useIonViewWillEnter, IonAlert, IonButtons, IonBackButton, IonHeader, IonToolbar, IonTitle, IonFooter, IonList, IonItemDivider, IonLoading, IonImg, IonThumbnail, IonGrid, IonRow, IonCol, IonSearchbar } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { add, arrowBack, car, cube, documentOutline, remove, thumbsUp, thumbsUpSharp, trash } from 'ionicons/icons';
import './Qrcode.css';
import { useHistory } from 'react-router-dom';
import InstrucoesCad from '../InstrucoesCad/InstrucoesCad';
import { instrucoesExcluir, clienteCarregar, requestService } from '../../../../../Utils/Services';
import QRCode from 'qrcode';
import imageSaidamax from './imgs/LogoCor.png';
import imageLogo from './imgs/Logo.png';
import imagePlayStore from './imgs/google-play.png';
import imageBrowsers from './imgs/browsers.png';

const Qrcode: React.FC = () => {
  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [InstrucoesPages, setInstrucoesPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayInstrucoes, setarrayInstrucoes] = useState<any>([]);
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [instrucoes, setInstrucoes] = useState("");
  const [showExcluirInstrucoes, setShowExcluirInstrucoes] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [chamarServico, setChamarServico] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showExcluding, setShowExcluding] = useState(false);
  const [imagem, setImagem] = useState<any>();

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";
    let clienteId = (window.localStorage.getItem("cliente") || "").replace('cliente%23', '');
    //QRCode.toDataURL('https://motorista.saidamax.com.br/avulsas/'+clienteId,
    QRCode.toDataURL('https://www.saidamax.com.br/store.html',
      {
        errorCorrectionLevel: 'H',
        type: 'image/png',
        scale: 3
      })
      .then(url => {
        console.log(url)
        setImagem(url);
      })
      .catch(err => {
        console.error(err)
      })
  });

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Vamos melhorar juntos as operações de carga e descarga?</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonCard color='primary' style={{ "box-shadow": "none", display: "flex", "alignItems": "center", "justifyContent": "center", textAlign: "center" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <img src={imageSaidamax} width="400PX" />
        </IonCard>
        <IonCard style={{ "box-shadow": "none" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          <IonCardHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardTitle style={{ display: "flex", "align-items": "center", "justify-content": "center", textAlign: "center" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>www.saidamax.com.br</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <IonGrid style={{ "margin-top": "50px", "border": "1px solid #dddddd", "padding": "10px", "box-shadow": "2px 2px 2px 1px rgba(0, 0, 0, 0.2)" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonCol size="4" style={{ display: "block", "alignItems": "center", "justifyContent": "center", textAlign: "center" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <div>
                <img src={imagem} />
              </div>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Baixe agora</IonLabel>
            </IonCol>
            <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <div>
                <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonThumbnail slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <img src={imageLogo} />
                  </IonThumbnail>
                  <IonSearchbar value={"Saidamax"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonThumbnail slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonThumbnail>
                  <img src={imagePlayStore} width="30%" />
                  <img src={imageBrowsers} width="45%" style={{margin:"10px"}} />
                </IonItem>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
      <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonCard style={{ "box-shadow": "none" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonCardHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardTitle style={{ display: "flex", "align-items": "center", "justify-content": "center", textAlign: "center" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Este estabelecimento é PARCEIRO dos MOTORISTAS</IonCardTitle>
          </IonCardHeader>
        </IonCard>

        <IonToolbar color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          <IonButton onClick={() => window.print()} expand="block" type="submit" fill='clear' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Imprimir QR Code</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Qrcode;
