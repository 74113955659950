import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar, useIonAlert, useIonModal, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { add, alertCircle, apps, arrowBack, checkmarkCircle, documentText, ellipse, flag, handRight, megaphone, pencil, power, receiptOutline, refresh, shareSocial } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { encerrarOperacao, incluirOperacao, operacaoAtualizarSemaforo, operacaoChamarMotorista, operacaoRegistrarAnotacao, requestService, urlBase } from '../../../../../Utils/Services';
import Acompanhar from '../Acompanhar/Acompanhar';
import './Operacoes.css';
import { Buffer } from 'buffer';

import validator from 'validator';

type Props = {
  recarregar: boolean;
};

const Operacoes: React.FC<Props> = (props: Props) => {

  const location = useLocation<any>();
  const inputRef = useRef<any>(null);

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  const [chamadaRealizada1, setChamadaRealizada1] = useState(false);
  const [operacaoId, setOperacaoId] = useState("");
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [operacaopk, setOperacaopk] = useState("");
  const [operacaosk, setOperacaosk] = useState("");
  const [operacaogsi2sk, setOperacaogsi2sk] = useState("");
  const [showCopiado, setShowCopiado] = useState(false);

  const [codigoBarras, setCodigoBarras] = React.useState('0');
  const [showModal, setShowModal] = useState(false);
  const [showModalCodigoBarras, setShowModalCodigoBarras] = useState(false);
  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);
  const [showModalTipoOperacao, setShowModalTipoOperacao] = useState(false);
  const [tipoOperacao, setTipoOperacao] = useState("Entrega");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [codigoLido, setCodigoLido] = useState("");
  const [tipoCarga, setTipoCarga] = useState(window.localStorage.getItem("tipoCarga") || "");
  const [showLoading, setShowLoading] = useState(false);
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [checked, setChecked] = useState(false);
  const [statusOperacao, setStatusOperacao] = useState<string>();

  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [placa, setPlaca] = useState("");
  const [pesquisa, setPesquisa] = useState<string>();
  const [lojas, setLojas] = useState<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));
  const [lojaCadastro, setLojaCadastro] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");
  const [stateUpdate, setStateUpdate] = useState(true);
  const [stateUpdate2, setStateUpdate2] = useState(true);

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
  });

  if (location && location != undefined && location.state && location.state != undefined && location.state.recarregar != undefined && location.state.recarregar) {
    if (!chamadaRealizada1) {
      setChamadaRealizada1(true);
      setChamadaRealizada(false);
      history.replace("/cliente/operacoes");
    }
  }

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  function onChangeCPF(e: any) {
    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    // console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }

  const openScanner = async () => {

    const opt: BarcodeScannerOptions = {
      formats: 'CODE_128',
      prompt: 'Escaneie o codigo de barras'
    }

    const data: any = await BarcodeScanner.scan(opt);

    if (data !== null) {
      setShowModalCodigoBarras(false);
      setShowModal(false);
      setCodigoBarras(data.text.match(/(.{1,4})/g).join(" "));
      setShowModalCodigoBarrasDigitar(true);
    }

  };

  function doRefresh(event: any) {
    carregarOperacoesSegmentada(statusOperacao);
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function chamarMotorista(pk: any, mensagem?: any) {
    let params = {
      mensagem: mensagem || ""
    }
    // console.log("entrou aqui");
    return fetch(operacaoChamarMotorista.url.replace("{pk}", pk),
      {
        method: operacaoChamarMotorista.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  function registrarAnotacao(pk: any, mensagem: any, indice: any) {
    if (!mensagem || mensagem.trim() == "") {
      return null;
    }

    let params = {
      mensagem: mensagem
    }
    // console.log("entrou aqui");
    return fetch(operacaoRegistrarAnotacao.url.replace("{pk}", pk),
      {
        method: operacaoRegistrarAnotacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  function atualizarSemaforo(value: any, semaforo: Number) {
    let semaforoAtual = value.semaforo || 1;

    if (semaforoAtual == semaforo) {
      return;
    }
    else {
      value.semaforo = semaforo;
      setStateUpdate(!stateUpdate);
    }

    let params = {
      semaforo: semaforo
    }

    // console.log("entrou aqui");
    fetch(operacaoAtualizarSemaforo.url.replace("{pk}", value.pk.replace(/#/g, '%23')),
      {
        method: operacaoAtualizarSemaforo.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      }).then((e) => {
        if (e.status != 200) {
          value.semaforo = semaforoAtual;
          setStateUpdate2(!stateUpdate2);
          mostrarToast({
            duration: 2000,
            position: 'bottom',
            message: 'Erro ao atualizar semaforo!'
          });
        }
      }).catch((e) => {
        console.log(value.semaforo, semaforoAtual);
        value.semaforo = semaforoAtual;
        setStateUpdate2(!stateUpdate2);
        mostrarToast({
          duration: 2000,
          position: 'bottom',
          message: 'Erro ao atualizar semaforo!'
        });
      });
  }

  function preencheCard(value: any, indice: any) {
    return (
      <IonCard className={"cardOperacao"} hidden={value.pk !== "zero" && (!pesquisa || pesquisa === "" || value.notasFiltro?.indexOf(pesquisa.toString()) > -1 /*value.operacaoChaveAcesso.substr(25, 9).indexOf(pesquisa.toString()) > -1*/) ? false : true} key={Math.random()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="tempo2">
          <div className="duracao">
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><span style={{ fontSize: "11px" }}>duração:</span> {tempoCorrido(new Date(value.dataInicio), (value.dataEncerramento ? new Date(value.dataEncerramento) : new Date()))}</IonLabel>
          </div>
        </div>

        <div className="loja">
          <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><span style={{ fontSize: "14px" }}>{value.clienteNome ? value.clienteNome : ""}</span></IonLabel>
        </div>

        <IonItem color={value.semaforo == 2 && value.status == "Em andamento" ? 'secondary' : value.semaforo == 3 && value.status == "Em andamento" ? 'tertiary' : 'primary'} style={{ width: "100%", borderRadius: "10px 10px 0 0" }} lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}  >
          <IonLabel style={{ margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {value.operacaoTipoOperacao.concat(" - ", value.tipoCarga, " ")}
            <IonChip color="tertiary" hidden={!value.sk.includes("usuarios")} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon icon={handRight} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Aberto manualmente</IonLabel>
            </IonChip>
          </IonLabel>
          <IonLabel hidden={value.status != "Em andamento"} slot='end' style={{
            background: '#fff', padding: '10px', height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px'
          }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon slot='end' style={{ fontSize: "24px" }} color='primary' icon={ellipse} onClick={() => { atualizarSemaforo(value, 1); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <IonIcon slot='end' style={{ fontSize: "24px" }} color='secondary' icon={ellipse} onClick={() => { atualizarSemaforo(value, 2); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <IonIcon slot='end' style={{ fontSize: "24px" }} color='tertiary' icon={ellipse} onClick={() => { atualizarSemaforo(value, 3); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonLabel>
          <IonButton slot='end' fill='clear' onClick={() => { setChamadaRealizada1(false); history.push("operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: value.id.replace(/#/g, '%23') }); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon slot="icon-only" color='light' icon={documentText} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </IonButton>

        </IonItem>



        <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCol size='auto' style={{ minWidth: "250px" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                <IonIcon icon={receiptOutline} slot="start" hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonItem style={{ width: "100%" }} lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel style={{ margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                    <IonText color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><h3>Notas Fiscais</h3></IonText>
                    {value.notasAdicionais.map((notasValue: any, index: any) => (value.notasFiltro += notasValue.notaFiscal.substr(25, 9).toString(),
                      <p style={{ color: "#000000", fontWeight: "bold" }}>{notasValue.notaFiscal ? notasValue.notaFiscal.substr(25, 9) : "Não informada"} | {notasValue.razaoSocial || "Não identificado"} | {notasValue.notaFiscal ? notasValue.notaFiscal.substr(6, 14).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : ""}</p>
                    ))}
                  </IonLabel>
                </IonItem>
              </IonItem>

            </IonCol>
            <IonCol size='auto' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                <IonIcon icon={receiptOutline} slot="start" hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonItem style={{ width: "100%" }} lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel style={{ margin: 0 }} className="ion-text-wrap" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonText color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><h3>Motorista</h3></IonText>
                    <IonChip color="success" hidden={!value.motoristaParceiro || true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                      <IonIcon size="small" icon={checkmarkCircle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
                      <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Motorista Parceiro</IonLabel>
                    </IonChip>
                    <p style={{ color: "#000000", fontWeight: "bold" }}>{value.motoristaNome}</p>
                    <p style={{ color: "#000000", fontWeight: "bold" }}>{cpf_mask(value.sk.replace('motorista#', ''))}</p>
                    <p style={{ color: "#000000", fontWeight: "bold" }}>Placa: {value.veiculo}</p>
                  </IonLabel>
                </IonItem>
              </IonItem>


            </IonCol>
            <IonCol size='auto' style={{ minWidth: "250px" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                <IonIcon icon={receiptOutline} slot="start" hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonItem style={{ width: "100%" }} lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel style={{ margin: 0 }} className="ion-text-wrap" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonText color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><h3>Anotações</h3></IonText>
                    {
                      value.anotacoes?.sort(function (a: any, b: any) {
                        if (a.data < b.data) {
                          return 1;
                        }
                        if (a.data > b.data) {
                          return -1;
                        }
                        // a must be equal to b
                        return 0;
                      }).slice(0, 5).map((anotacoesValue: any) => (<p style={{ color: "#000000", fontWeight: "bold" }}>{new Date(anotacoesValue.data).toLocaleTimeString()} - {anotacoesValue.anotacao}</p>))}
                  </IonLabel>
                </IonItem>
              </IonItem>

            </IonCol>
          </IonRow>
        </IonGrid>

        <IonFab horizontal="start" vertical="bottom" style={{ "borderRadius": "50" }} hidden={value.status != "Em andamento" || true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonFabButton color="secondary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon icon={apps} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonIcon>
          </IonFabButton>
          <IonFabList side="end" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonFabButton color="light" onClick={() => {
              mostartAlerta({
                header: 'Enviar mensagem ao motorista',
                message: 'Digite a mensagem abaixo',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    value: 'Hora de descarregar venha até a doca.',
                    placeholder: 'Digite a mensagem',
                  }
                ],
                buttons: ['Cancel', { text: 'Ok', handler: (d) => chamarMotorista(value.pk.replace(/#/g, '%23'), d.mensagem) }],
                onDidDismiss: (e) => console.log('did dismiss'),
              });
            } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon icon={megaphone} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            </IonFabButton>
          </IonFabList>
        </IonFab>

        <IonItem lines="none" hidden={value.status != "Em andamento" || true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonChip slot="end" color='tertiary' onClick={(e) => mostartAlerta({
            header: 'Enviar mensagem ao motorista',
            message: 'Digite a mensagem abaixo',
            inputs: [
              {
                name: 'mensagem',
                type: 'textarea',
                id: 'name2-id',
                value: 'Hora de descarregar venha até a doca.',
                placeholder: 'Digite a mensagem',
              }
            ],
            buttons: ['Cancel', { text: 'Ok', handler: (d) => chamarMotorista(value.pk.replace(/#/g, '%23'), d.mensagem) }],
            onDidDismiss: (e) => console.log('did dismiss'),
          })} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon icon={megaphone} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Chamar motorista</IonLabel>
          </IonChip>
        </IonItem>

        <IonItem hidden={value.status != "Em andamento"} style={{ padding: 0, margin: 0, "borderRadius": "10px" }} color="grey" lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => mostartAlerta({
            header: 'Enviar mensagem ao motorista',
            message: 'Digite a mensagem abaixo',
            inputs: [
              {
                name: 'mensagem',
                type: 'textarea',
                id: 'name2-id',
                value: 'Hora de descarregar venha até a doca.',
                placeholder: 'Digite a mensagem',
              }
            ],
            buttons: ['Cancel', {
              text: 'Ok', handler: (d) => {
                setShowLoading(true);
                chamarMotorista(value.pk.replace(/#/g, '%23'), d.mensagem).then(result => {
                  if (result.status === 200) {
                    if (value.anotacoes) {
                      value.anotacoes?.push({ data: new Date().toISOString(), anotacao: d.mensagem });
                    }
                    else {
                      value.anotacoes = [{ data: new Date().toISOString(), anotacao: d.mensagem }];
                    }
                    mostrarToast({
                      duration: 2000,
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Chamada realizada com sucesso!'
                    });
                  }
                  else {
                    mostrarToast({
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Erro ao tentar chamar!'
                    });
                  }
                }).catch(() => {
                  mostrarToast({
                    position: 'middle',
                    buttons: [{ text: 'ok', handler: () => fecharToast() }],
                    message: 'Erro ao tentar chamar!'
                  });
                }).finally(() => {
                  setShowLoading(false);
                });
                return true;
              }
            }],
            onDidDismiss: (e) => console.log('did dismiss'),
          })} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon icon={megaphone} style={{ padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Chamar</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => {
            mostartAlerta({
              backdropDismiss: false,
              header: 'Registrar anotação na operação',
              message: 'Digite a anotação abaixo',
              inputs: [
                {
                  name: 'mensagem',
                  type: 'textarea',
                  id: 'name2-id',
                  placeholder: 'Digite aqui a anotação',
                }
              ],
              buttons: ['Cancel', {
                text: 'Incluir', handler: (d) => {
                  setShowLoading(true);
                  let retorno = registrarAnotacao(value.pk.replace(/#/g, '%23'), d.mensagem, indice);
                  if (retorno) {
                    retorno?.then((e) => {
                      if (e.status === 200) {
                        if (value.anotacoes) {
                          value.anotacoes?.push({ data: new Date().toISOString(), anotacao: d.mensagem });
                        }
                        else {
                          value.anotacoes = [{ data: new Date().toISOString(), anotacao: d.mensagem }];
                        }

                        console.log(value);
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Sucesso na inclusão!'
                        });
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro na inclusão!'
                        });
                      }
                    }).catch((e) => {
                      console.log(e);
                      mostrarToast({
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Erro na inclusão!'
                      });
                    }).finally(() => {
                      setShowLoading(false);
                    });
                    return true;
                  }
                  else {
                    mostrarToast({
                      duration: 2000,
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Digite uma anotação para incluir.'
                    });
                    setShowLoading(false);
                    return false;
                  }
                }
              }],
              onDidDismiss: (e) => { console.log('did dismiss'); }
            });
          } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon icon={pencil} style={{ padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Anotação</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' hidden={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon icon={shareSocial} style={{ padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Compartilhar</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => { setChamadaRealizada1(false); history.push("operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: value.id.replace(/#/g, '%23') }); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon icon={documentText} style={{ padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Detalhes</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton slot='end' style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
            mostartAlerta({
              backdropDismiss: false,
              header: 'Encerrar operação',
              message: 'Realizar <strong>encerramento</strong>?',
              buttons: [
                {
                  text: 'Cancelar',
                  role: 'cancel',
                },
                {
                  text: 'Confirmar',
                  cssClass: 'primary',
                  handler: () => {
                    setShowLoading(true);
                    let retorno = encerrar2(value.pk);
                    if (retorno) {
                      retorno?.then(res => {
                        if (res.status === 200) {
                          value.pk = "zero";
                          mostrarToast({
                            duration: 2000,
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Operação encerrada com sucesso.'
                          });
                        }
                        else {
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro ao tentar encerrar.'
                          });
                        }
                      }).catch(() => {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar encerrar.'
                        });
                      }).finally(() => {
                        setShowLoading(false);
                      });
                      return true;
                    }
                    else {
                      mostrarToast({
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Erro ao tentar encerrar.'
                      });
                      setShowLoading(false);
                      return false;
                    }
                  }
                }
              ],
              onDidDismiss: (e) => console.log('did dismiss'),
            });
          } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon icon={power} color={"danger"} style={{ padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Encerrar</IonLabel>
          </IonSegmentButton>

        </IonItem>
      </IonCard >
    )
  }

  function encerrar2(operacao: string) {

    return fetch(encerrarOperacao.url.replace("{id}", operacao.replace(/#/g, "%23")),
      {
        method: encerrarOperacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });
  }

  const loadItens = () => {

    if (!chamadaRealizada) {
      // console.log("load itens - chamada para carregamento");
      setStatusOperacao("Em");
      setChamadaRealizada(true);
      carregarOperacoesSegmentada(statusOperacao || "Em");
    }

    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="primeira" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonCardHeader id="primeira" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Neste momento não há nenhuma operação.</IonCardTitle>
            <IonCardSubtitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonCardSubtitle>
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonLabel>
          </IonCardHeader>
          <IonCardContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          </IonCardContent>
        </IonCard>
      )
    }

    return arrayOperacoes.map((value: any, index: any, arr: any) =>

      //(new Date(value.dataInicio).toLocaleString('pt-BR').substring(0,10)===dia)?
      (value.status === "Em andamento" && !arr[index - 1]) ?
        (<>
          <IonCard className={"card"} key={Math.random()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCardHeader id={value.dataInicio} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className={"cabecalho"} >{"Em andamento"}</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          {preencheCard(value, index)}
        </>)
        :
        (value.status !== "Em andamento" && ((index === 0) || (arr[index - 1] && new Date(value.dataEncerramento).toLocaleString('pt-BR').substring(0, 10) !== new Date(arr[index - 1].dataEncerramento).toLocaleString('pt-BR').substring(0, 10)))) ?
          (<>
            <IonCard className={"card"} key={Math.random()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCardHeader id={value.dataEncerramento} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonCardTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <div className={"cabecalho"} >{(value.dataEncerramento ? new Date(value.dataEncerramento) : new Date()).toLocaleString('pt-BR').substring(0, 10) === new Date().toLocaleString('pt-BR').substring(0, 10) ? "Hoje" : new Date(value.dataEncerramento).toLocaleString('pt-BR').substring(0, 10)}</div>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            {preencheCard(value, index)}
          </>
          ) :
          (
            <>
              {preencheCard(value, index)}
            </>
          )
    );
  }

  function cancelarScan() {
    setShowModalCodigoBarrasDigitar(false);
    setShowModalCodigoBarras(false);
  }

  function modoCodigoBarras() {
    if (showModalCodigoBarras) {
      //Esta com escaneamento ativo
      setShowModalCodigoBarras(false);
      setShowModalCodigoBarrasDigitar(true);
    } else {
      setShowModalCodigoBarras(true);
      setShowModalCodigoBarrasDigitar(false);
    }
  }

  async function carregarOperacoesSegmentada(filtro: any, lojasParam?: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const resp: Response = await fetch(urlBase.url + "/operacoes?filtro=" + filtro + "&lojas=" + Buffer.from(JSON.stringify(lojasParam || lojas)).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setOperacaopk(res.LastEvaluatedKey.pk);
          setOperacaosk(res.LastEvaluatedKey.sk);
          setOperacaogsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayOperacoes(res.Items);
      });
    }
    consultar();
  }

  async function carregarMaisOperacoes($event: CustomEvent<void>) {
    if (operacaogsi2sk.includes(statusOperacao || "Em")) {
      await carregarMaisOperacoesChamada().then(() => {
        // console.log("terminou resultados");
      });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisOperacoesChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    //console.log(urlBase.url + "/operacoes?isk=" + operacaogsi2sk + "&pk=" + operacaopk + "&sk=" + operacaosk);
    const res: Response = await fetch(urlBase.url + "/operacoes?filtro=" + statusOperacao + "&isk=" + operacaogsi2sk + "&pk=" + operacaopk + "&sk=" + operacaosk + "&lojas=" + Buffer.from(JSON.stringify(lojas)).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (operacoes) => {
        //console.log(arrayOperacoes.length);
        for (let i = 0; i < operacoes.Items.length; i++) {
          //console.log("resultados");
          arrayOperacoes.push(operacoes.Items[i]);
        }
        //console.log(arrayOperacoes.length);
        //console.log(operacoes.LastEvaluatedKey);
        if (operacoes.LastEvaluatedKey) {
          // console.log("entrou aqui");
          setOperacaopk(operacoes.LastEvaluatedKey.pk);
          setOperacaosk(operacoes.LastEvaluatedKey.sk);
          setOperacaogsi2sk(operacoes.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setOperacaosk("");
          setOperacaogsi2sk("");
          setDesabilitarInfiniteScroll(true);
        }
      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }

  function tempoCorrido(horario: Date, horarioFim: Date) {
    var g = Math.ceil((horarioFim.getTime() - horario.getTime()) / 1000 / 60);
    if (g > 1440) {
      return Math.floor(g / 60).toString() + 'h';
    }
    else if (g > 60) {
      return Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + '';
    }
    else {
      return g.toString() + 'min';
    }
  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };

  function validarCampos() {
    if (nome.length < 3 || cpf.length != 14 || telefone.length != 15 || placa.length === 0) {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }
    else {
      if (!validarCpfNovo(cpf)) {
        setTextToast("CPF Inválido.");
        setShowToast(true);
      }
      else if (!validator.isMobilePhone(telefone, 'pt-BR')) {
        setTextToast("Celular Inválido.");
        setShowToast(true);
      }
      else if (placa.length != 7) {
        setTextToast("Placa Inválida.");
        setShowToast(true);
      }
      else {
        return true;
      }
    }
  }

  function validarCpfNovo(val: any): boolean {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else { return false; }
  }

  function iniciarOperacao() {
    if (!validarCampos())
      return;

    if (codigoBarras === undefined || codigoBarras.replace(/\ /, '') === '') {

      setAlertaPreenchimento(true);
    }
    else {

      if (botaoAtivo) {
        setShowLoading(true);
        setBotaoAtivo(false);
        setShowModalCodigoBarrasDigitar(false);

        let objRequest = {
          "motoristaNome": nome,
          "motoristaDocumento": cpf || "",
          "motoristaTelefone": telefone || "",
          "motoristaPlaca": placa || "",
          "tipoOperacao": tipoOperacao,
          "tipoCarga": tipoCarga,
          "chaveAcesso": codigoBarras.replace(/ /g, ""),
          "loja": (lojaCadastro && lojaCadastro != "") ? lojaCadastro : ""
        };

        requestService(incluirOperacao.url, {
          method: incluirOperacao.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          body: JSON.stringify(objRequest),
          credentials: 'include'
        },
          history,
          (response: any) => {
            if (response.message) {
              setBotaoAtivo(true);
              setShowLoading(false);
              setShowModalCodigoBarrasDigitar(false);
              setShowModalTipoOperacao(false);
              carregarOperacoesSegmentada(statusOperacao);
              setTextToast("Operação criada com sucesso!");
              setShowToast(true);
            }
          },
          (error: any) => {
            setTextToast("Erro ao tentar inserir! Tente novamente!");
            setShowToast(true);
            setBotaoAtivo(true);
            setShowLoading(false);
          })

      }
    }
  }

  async function handleSegmentChange(e: any) {
    setStatusOperacao(e.detail.value);
    await carregarOperacoesSegmentada(e.detail.value);
  }

  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(Acompanhar, {
    estabelecimento: "estabelecimento 1",
    codigoBarras: "codigo teste",
    operacaoid: operacaoId,
    onDismiss: handleDismiss
  });

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonIcon slot="icon-only" icon={flag} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Operações</IonTitle>
          <IonSelect hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true}
          name='Lojas'
          placeholder='Selecione as lojas'
          title='Lojas'
          value={lojas}
          multiple={true}
          cancelText="Cancel"
          okText="Ok"
          onIonChange={e => { if (Array.isArray(e.detail.value) && e.detail.value.length < 1) { mostartAlerta('Informações na tela não atualizadas. Selecione ao menos uma loja na lista.', [{ text: 'Ok' }]); console.log("mostrar tela"); } else { setLojas(e.detail.value); carregarOperacoesSegmentada(statusOperacao, e.detail.value); } } }
          slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
              <IonSelectOption value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
            ))}
          </IonSelect>
        </IonToolbar>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonSearchbar value={pesquisa} onIonClear={(e) => setPesquisa(undefined)} onIonChange={(e) => { setPesquisa(e.detail.value); } } placeholder='Pesquise pelo número da nota nesta tela...' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonSearchbar>
        </IonToolbar>
        <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonSegment value={statusOperacao} onIonChange={(e) => { scrollToTop(); handleSegmentChange(e); setPesquisa(""); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonSegmentButton value="Em" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Andamento</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="Concluido" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Concluídos</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollEvents={true} ref={contentRef} color='warning' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonFab vertical="top" horizontal="end" slot="fixed" edge placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          <IonFabButton color="tertiary" onClick={() => {
            setCPF("");
            setNome("");
            setTelefone("");
            setPlaca("");
            setShowModalTipoOperacao(true);
          } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon color="light" icon={add} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonFab vertical="top" horizontal="start" slot="fixed" edge placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          <IonFabButton color="tertiary" onClick={() => { carregarOperacoesSegmentada(statusOperacao); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonIcon color="light" icon={refresh} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRefresherContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonRefresherContent>
        </IonRefresher>
        <IonModal isOpen={showDetalhes} onDidDismiss={() => { setDesabilitarInfiniteScroll(false); setShowDetalhes(false); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <Acompanhar estabelecimento="estabelecimento 1" codigoBarras="codigo teste" operacaoid={operacaoId} onDismiss={() => { setDesabilitarInfiniteScroll(false); setShowDetalhes(false) }}></Acompanhar>

        </IonModal>

        <div className='OperacoesSlides'>
          {loadItens()}
        </div>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisOperacoes(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais operações..." placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonToast
          isOpen={showCopiado}
          onDidDismiss={() => setShowCopiado(false)}
          message="Campo copiado."
          duration={200}
        />

        <div className="content">

          <IonModal isOpen={showModalCodigoBarrasDigitar} onDidPresent={() => { inputRef.current.setFocus(); } } onDidDismiss={() => setShowModalCodigoBarrasDigitar(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Informe a Nota Fiscal</IonTitle>
                <IonButtons slot="start" onClick={() => { cancelarScan(); setShowModalCodigoBarrasDigitar(false); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonIcon slot="icon-only" icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "fontSize": "14px" }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonLabel className="ion-text-wrap" position="stacked" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Informe a <b>chave de acesso</b> (código de barras) na <b>nota</b>:</IonLabel>
                <IonTextarea ref={(ref) => inputRef.current = ref} rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="clique aqui para digitar ou utilize o leitor de código de barras" onIonChange={e => space(e.detail.value!, 4)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonTextarea>
              </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonButton expand="block" size='large' disabled={!botaoAtivo} onClick={() => { iniciarOperacao(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Iniciar Operação!</IonButton>
                <IonButton expand="block" fill="clear" color="tertiary" onClick={() => { openScanner(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Utilizar câmera do celular</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
          <IonModal isOpen={showModalCodigoBarras} onDidDismiss={() => setShowModalCodigoBarras(false)} className='my-custom-class camera' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{codigoLido}</IonLabel>
            <IonLabel className="helper" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Precisamos que informe o <b>código de barras</b> da nota.</IonLabel>
            <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <IonButton color="primary" onClick={() => { openScanner(); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Leitor de Código de Barras</IonButton>
              <IonButton color="primary" onClick={modoCodigoBarras} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Digitar o Código de Barras</IonButton>
              <IonButton fill="clear" slot="end" color="tertiary" onClick={cancelarScan} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cancelar</IonButton>
            </div>
          </IonModal>
          <IonModal isOpen={showModalTipoOperacao} onDidDismiss={() => setShowModalTipoOperacao(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cadastro de Operação</IonTitle>
                <IonButtons slot="start" onClick={() => setShowModalTipoOperacao(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonIcon slot="icon-only" icon={arrowBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonList lines="full" class="ion-no-margin" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Dados da Operação
                  </IonLabel>
                </IonListHeader>
                <IonItem lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Loja</IonLabel>
                  <IonSelect name='lojaCadastro' placeholder='Selecione a loja' title='Lojas' value={lojaCadastro} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojaCadastro(e.detail.value); } } slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Tipo de operação</IonLabel>
                  <IonSelect value={tipoOperacao} onIonChange={(e) => { setTipoOperacao(e.detail.value || ""); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonSelectOption value="Entrega" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Entrega</IonSelectOption>
                    <IonSelectOption value="Coleta" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Coleta</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Carga</IonLabel>
                  <IonSelect value={tipoCarga} onIonChange={(e) => { window.localStorage.setItem("tipoCarga", e.detail.value || ""); setTipoCarga(e.detail.value || ""); } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonSelectOption value="Seca" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Seca</IonSelectOption>
                    <IonSelectOption value="Perecível" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Perecível</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem hidden={true} lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Carga Paletizada</IonLabel>
                  <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
              </IonList>

              <IonList lines="full" class="ion-no-margin" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonListHeader lines="full" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Informações do Motorista
                  </IonLabel>
                </IonListHeader>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Nome*</IonLabel>
                  <IonInput placeholder="Nome do motorista" value={nome} required onIonInput={(e: any) => setNome(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >CPF*</IonLabel>
                  <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Celular*</IonLabel>
                  <IonInput type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Veículo*</IonLabel>
                  <IonInput placeholder="Digite a placa do veículo" value={placa} required maxlength={7} onIonInput={(e: any) => setPlaca(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
              </IonList>

              <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => { setShowLoading(false); }}
                message={'Incluindo...'}

              />


            </IonContent>
            <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonButton expand="block" size='large' color="primary" onClick={() => { if (!validarCampos()) { return; } if (tipoOperacao === "Entrega") { setCodigoBarras(""); setShowModalCodigoBarrasDigitar(true); } else { setCodigoBarras("0"); iniciarOperacao(); } } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Registrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
          <IonToast
            isOpen={showToast}
            position="middle" //top, bottom, middle
            onDidDismiss={() => setShowToast(false)}
            message={textToast}
            duration={1000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Operacoes;
