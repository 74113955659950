import { IonContent, IonPage, IonButton, IonLabel, IonInput, IonHeader, IonRadioGroup, IonList, IonItem, IonRadio, IonToolbar, IonTitle, IonFooter, IonButtons, IonIcon, IonAlert, IonCol, IonGrid, IonRow, IonSelect, IonSelectOption, useIonViewDidEnter } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './EquipeCad.css';
import '@capacitor-community/http';
import { alteraEquipe, cadastraEquipe, requestService } from '../../../../../Utils/Services';
import { arrowBack } from 'ionicons/icons';

type MyModalProps = {
  usuario: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const EquipeCad: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory();

  const [textToast, setTextToast] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const campoTelefone = useRef<any>(null);
  const [selected, setSelected] = useState("Usuário");
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [alteracao, setAlteracao] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [lojas, setLojas] = useState<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));
  const [chamadaRealizada, setChamadaRealizada] = useState(false);


  if (!chamadaRealizada) {

    console.log("eteeee", props.usuario);

    if (props.usuario) {
      setChamadaRealizada(true);
      setAlteracao(true);
      setCPF(formatCPF(props.usuario.cpf));
      setNome(props.usuario.nome);
      setEmail(props.usuario.email);
      setTelefone(formataTelefone(props.usuario.telefone));
      setSelected(props.usuario.perfil);

      let arrayLojas: string[] = [];
      props?.usuario?.lojas?.map((valor: any) => (
        arrayLojas.push(valor.clienteLojaId)
      ));

      setLojas(arrayLojas);
    }

  }


  function onChangeCPF(e: any) {

    //console.log(cpf.length);
    //console.log(e.target.value.length);
    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    // if (e.keyCode === ENTER_KEY_CODE) {
    //   e.target.value = e.target.value.substring(0, 15);
    //   setTelefone(e.target.value.substring(0, 15));
    //   cadastrar();
    // }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    console.log(telefone.length);
    console.log(e.target.value.length);

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }

  function formatCPF(e: string) {
    let cpfval = e.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    return cpfval;
  }

  function formataTelefone(numero: any) {

    let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
    BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
    return BRNumber;

  }

  function cadastrar() {


    console.log(nome.length);
    console.log(cpf.length);
    console.log(telefone.length);
    console.log(window.localStorage.getItem("lojas"));
    console.log(JSON.parse(window.localStorage.getItem("lojas") || "").length);
    console.log(lojas.length);
    if (nome.length < 3 || cpf.length != 14 || telefone.length != 15 ||
      (
        (window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0 && lojas.length < 1)
      )
    ) {
      setTextToast("Todos os campos são obrigatórios, exceto o email");
      setShowErro(true);
    }
    else {
      setShowIncluirUsuario(true);
    }
  }

  return (
    <>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cadastro de equipe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonGrid placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position='stacked' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Dados Cadastrais</IonLabel>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position="stacked" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Nome*</IonLabel>
                  <IonInput placeholder="Nome completo" value={nome} required onIonInput={(e: any) => setNome(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                  <IonLabel position="stacked" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>CPF*</IonLabel>
                  <IonInput disabled={alteracao} type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position="stacked" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Telefone*</IonLabel>
                  <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position="stacked" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>E-mail</IonLabel>
                  <IonInput value={email} placeholder="Email para comunicação" type="email" onIonInput={(e: any) => setEmail(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
              </IonList>
            </IonCol>
            <IonCol placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem lines="none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position='stacked' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Perfil</IonLabel>
                </IonItem>
                <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonItem placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Usuário</IonLabel>
                    <IonRadio slot="start" value="Usuário" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </IonItem>
                  <IonItem placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Administrador</IonLabel>
                    <IonRadio slot="start" value="Cliente" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </IonItem>
                </IonRadioGroup>
                <IonItem lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position='stacked' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Lojas</IonLabel>
                </IonItem>
                <IonItem lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position='stacked' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Lojas</IonLabel>
                  <IonSelect name='Lojas' placeholder='Selecione lojas' title='Lojas' value={lojas} multiple={true} cancelText="Cancel" okText="Ok" onIonChange={e => { console.log("lojas", e.detail.value); setLojas(e.detail.value); } } slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.clienteLojaId} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{valor.clienteLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert isOpen={showIncluirUsuario}
          onDidDismiss={() => setShowIncluirUsuario(false)}
          header={'Cadastro de usuário'}
          message={'Você <strong>confirma</strong> inclusão?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                const objectRequest = {
                  cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
                  nome: nome,
                  email: email,
                  telefone: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
                  perfil: selected,
                  lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
                    return lojas.includes(value.clienteLojaId);
                  })
                }


                if (!alteracao) {

                  requestService(cadastraEquipe.url, {
                    method: cadastraEquipe.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include',
                    body: JSON.stringify(objectRequest)
                  },
                    history,
                    (response: any) => {
                      setNome("");
                      setCPF("");
                      setTelefone("");
                      setEmail("")
                      setSelected("Usuário");
                      setTextToast("Cadastro realizado com sucesso.");
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
                else {
                  requestService(alteraEquipe.url, {
                    method: alteraEquipe.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include',
                    body: JSON.stringify(objectRequest)
                  },
                    history,
                    (response: any) => {
                      setNome("");
                      setCPF("");
                      setTelefone("");
                      setEmail("")
                      setSelected("Usuário");
                      setTextToast("Alteração realizada com sucesso.");
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
              }
            }
          ]}
        />

        <IonAlert isOpen={showSucesso}
          onDidDismiss={() => { setShowSucesso(false); }}
          header={'Sucesso'}
          message={textToast}
          backdropDismiss={false}
          buttons={[
            {
              text: 'OK',
              handler: () => { setShowSucesso(false); props.closeAction(); }
            }
          ]} />

        <IonAlert isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />
      </IonContent>
      <IonFooter className="ion-no-border" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>CADASTRAR</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default EquipeCad
