import { IonContent, IonPage, IonButton, IonToast, IonLabel, IonInput, IonHeader, IonList, IonItem, IonToolbar, IonTitle, IonFooter, IonButtons, IonIcon, useIonViewWillEnter, IonBackButton, useIonViewWillLeave, useIonViewDidLeave, useIonViewDidEnter, IonLoading, IonAlert } from '@ionic/react';
import React from 'react';
import './TermosPolitica.css';
import '@capacitor-community/http';
import { arrowBack } from 'ionicons/icons';

const TermosPolitica: React.FC = () => {

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";

  });

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonToolbar color="primary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel className="ion-text-wrap" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Termos de Uso e política de privacidade</IonLabel>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <a target='_blank' href={'https://saidamax-plt-welcome.s3.us-east-2.amazonaws.com/termos/Termos_de_uso.pdf'}>Ler os termos de uso</a>
          </IonItem>
          <IonItem lines='none' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <a target='_blank' href={'https://saidamax-plt-welcome.s3.us-east-2.amazonaws.com/termos/Termos_de_uso.pdf'}>Ler a política de privacidade</a>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TermosPolitica;
